import { useEffect, useState } from "react"
import "./searchKeywordMenu.css"
import { FooterString } from "../../Utils/Constants"
import filterList from "../../assets/svg/filter-list.svg"
import Modal from 'react-modal';
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
        width: "80%"
    },
    Modal: {
        padding: '0px'
    }
};



const SearchKeywordMenu = ({ backButton, assetList, AssetTypeList, handelSelectAssetTypeList, handelSelectAssetTypeListBackFun, assetCategorizationSummaryLoading }) => {
    const [KeyWord, setkeyWord] = useState("")

    const [selectKey, setSelectKey] = useState("Select Category")

    let subtitle;
    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);

    const handleSearchBtn = () => {
        if (selectKey !== "") {
            localStorage.setItem("searchFilter", selectKey);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            navigate(`/equipment`, { state: assetList?.CategoryDetails });
            closeModal();
        }
    };

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleSearch = useDebouncedCallback(
        // function
        (searchText) => {
            if (searchText?.target.value !== "") {
                localStorage.setItem("searchFilter", searchText?.target.value);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                navigate(`/equipment`, { state: assetList?.CategoryDetails });
                closeModal();
                // window.location.reload();
            }
        },
        // delay in ms
        1000
    );

    const handleFilter = (data, type) => {
        if (type === "assetList") {
            const filterDate = data?.filter((item) =>
                item.AssetTypeName?.toLowerCase().includes(KeyWord?.toLowerCase())
            );

            return filterDate

        } else if (type === "Category") {
            const filterDate = data?.filter((item) =>
                item.CategoryName?.toLowerCase().includes(KeyWord?.toLowerCase())
            );

            return filterDate
        } else if (type === "Tier") {
            const filterDate = data?.filter((item) =>
                item?.[
                    `Tier${assetList?.tierCount}Name`
                ]?.toLowerCase().includes(KeyWord?.toLowerCase())
            );

            return filterDate
        }
    }

    const selectAssetDetailsData = assetList?.selectAssetDetails?.CategoryDetails?.sort((a, b) => a.CategoryName.localeCompare(b.CategoryName))

    return (
        <>
            <div className="SearchKeywordMenu">
                <div className="Search_Input_Box"> <input type="text" placeholder="Hit enter to search..." autocomplete="off" className="Search_box" onKeyDown={(event) => event.key === 'Enter' ? handleSearchBtn() : null} onChange={(e) => setSelectKey(e.target.value)} /></div>
                <div className="Search_Key_Word_Box">
                    {!assetCategorizationSummaryLoading ?
                        <div
                            className="Dashboard_Navigation_Card"
                            sx={{
                                background: "#009688",
                            }}
                            onClick={() => {
                                closeModal();
                                handelSelectAssetTypeList(assetList?.selectAssetDetails, {
                                    Type: "AssetList",
                                })
                            }
                            }
                        >
                            <div
                                className={assetList?.CategoryDetails?.CategoryId ? "Search_Key_Word_Key" : " Search_Key_Word_Key_Select"}
                            >
                                All
                            </div>
                        </div> : null}
                    {assetCategorizationSummaryLoading ? (
                        <div
                            style={{
                                height: "300px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <div className="spinner-container">
                                <div className="loading-spinner"></div>
                            </div>
                        </div>
                    ) : assetList?.selectAssetDetails &&
                        Object.keys(assetList?.selectAssetDetails).length ===
                        0 ? (
                        handleFilter(AssetTypeList, "assetList")?.map((item) => {
                            return (
                                <div
                                    className="Dashboard_Navigation_Card"
                                    sx={{
                                        background: "#009688",
                                    }}
                                    onClick={() => {
                                        closeModal();
                                        handelSelectAssetTypeList(item, {
                                            Type: "AssetList",
                                        })
                                    }
                                    }
                                >
                                    <div className="Search_Key_Word_Key">
                                        {item?.AssetTypeName}
                                    </div>
                                </div>
                            );
                        })
                    ) :
                        handleFilter(selectAssetDetailsData, "Category")?.map(
                            (CategoryItem) => {
                                return (
                                    <div
                                        className="Dashboard_Navigation_Card"
                                        sx={{
                                            background: "#009688",
                                        }}
                                        onClick={() => {
                                            closeModal();
                                            handelSelectAssetTypeList(CategoryItem, {
                                                Type: "CategoryDetails",
                                            })
                                        }
                                        }
                                    >
                                        <div className={assetList?.CategoryDetails?.CategoryId === CategoryItem.CategoryId ? "Search_Key_Word_Key_Select" : "Search_Key_Word_Key"}>
                                            {CategoryItem?.CategoryName}
                                        </div>
                                    </div>
                                );
                            }
                        )}

                </div>
                <div className="Search_Input_Box">
                    <a href={`tel:${FooterString.NUMBER}`} className="Search_Input_link"><div>{FooterString.NUMBER}</div></a>
                    <a href={`mailto:${FooterString.EMAIL}`} className="Search_Input_link"><div className="line_break">{FooterString.EMAIL}</div></a>
                </div>
            </div>

            <div className="SearchKeywordMenu_sm">
                <div className="SearchKeywordMenu_sm_box">
                    <img src={filterList} alt="" onClick={openModal} className="SearchKeywordMenu_img" />
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="">
                        <div className="Search_Input_Box"> <input type="text" placeholder="Hit enter to search..." autocomplete="off" className="Search_box" onKeyDown={(event) => event.key === 'Enter' ? handleSearchBtn() : null} onChange={(e) => setSelectKey(e.target.value)} /></div>
                        <div className="Search_Key_Word_Box Search_Key_Word_Box_Sm">
                            {!assetCategorizationSummaryLoading ?
                                <div
                                    className="Dashboard_Navigation_Card"
                                    sx={{
                                        background: "#009688",
                                    }}
                                    onClick={() => {
                                        closeModal();
                                        handelSelectAssetTypeList(assetList?.selectAssetDetails, {
                                            Type: "AssetList",
                                        })
                                    }
                                    }
                                >
                                    <div
                                        className={assetList?.CategoryDetails?.CategoryId ? "Search_Key_Word_Key" : " Search_Key_Word_Key_Select"}
                                    >
                                        All
                                    </div>
                                </div> : null}
                            {assetCategorizationSummaryLoading ? (
                                <div
                                    style={{
                                        height: "300px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {" "}
                                    <div className="spinner-container">
                                        <div className="loading-spinner"></div>
                                    </div>
                                </div>
                            ) : assetList?.selectAssetDetails &&
                                Object.keys(assetList?.selectAssetDetails).length ===
                                0 ? (
                                handleFilter(AssetTypeList, "assetList")?.map((item) => {
                                    return (
                                        <div
                                            className="Dashboard_Navigation_Card"
                                            sx={{
                                                background: "#009688",
                                            }}
                                            onClick={() => {
                                                closeModal();
                                                handelSelectAssetTypeList(item, {
                                                    Type: "AssetList",
                                                })
                                            }
                                            }
                                        >
                                            <div className="Search_Key_Word_Key">
                                                {item?.AssetTypeName}
                                            </div>
                                        </div>
                                    );
                                })
                            ) :
                                handleFilter(selectAssetDetailsData, "Category")?.map(
                                    (CategoryItem) => {
                                        return (
                                            <div
                                                className="Dashboard_Navigation_Card"
                                                sx={{
                                                    background: "#009688",
                                                }}
                                                onClick={() => {
                                                    closeModal();
                                                    handelSelectAssetTypeList(CategoryItem, {
                                                        Type: "CategoryDetails",
                                                    })
                                                }
                                                }
                                            >
                                                <div className={assetList?.CategoryDetails?.CategoryId === CategoryItem.CategoryId ? "Search_Key_Word_Key_Select" : "Search_Key_Word_Key"}>
                                                    {CategoryItem?.CategoryName}
                                                </div>
                                            </div>
                                        );
                                    }
                                )}

                        </div>
                        <div className="Search_Input_Box">
                            <a href={`tel:${FooterString.NUMBER}`} className="Search_Input_link"><div>{FooterString.NUMBER}</div></a>
                            <a href={`mailto:${FooterString.EMAIL}`} className="Search_Input_link"><div className="line_break">{FooterString.EMAIL}</div></a>
                        </div>
                    </div>
                </Modal>
            </div>
            {/* ------------------------------ */}

        </>
    )
}
export default SearchKeywordMenu