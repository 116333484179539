export const ReCAPTCHA_sitekey = "6Le8jo0nAAAAALylOLaNPkdV90yFnNDLtJ5JcIWu";
export const header = {
  Equipment: "Equipment",
  About: "About",
  Contact: "Contact",
  Subscribe: "Subscribe",
  Submit: "Submit",
  Cancel: "Cancel",
};

export const FooterString = {
  PROUD_MEMBERS_OF: "PROUD MEMBERS OF",
  NUMBER: "+1 (800) 478-5840",
  EMAIL: "sales@calvingroupinc.com",
};

export const DashboardString = {
  EQUIPMENT_CATEGORIES: "EQUIPMENT CATEGORIES",
};

export const DetailString = {
  EQUIPMENT_DETAILS: "EQUIPMENT DETAILS",
};

export const AboutString = {
  EXPERIENCE:
    "WITH OVER 30 YEARS OF EXPERIENCE, WE SPECIALIZE IN BUYING AND SELLING USED ASPHALT EQUIPMENT.",
  Contact_Us: "Contact Us",
};
