const CommonInput = ({
  title,
  values,
  errors,
  handleChange,
  touched,
  id,
  name,
  type,
  placeholder,
}) => {
  return (
    <>
      <div className="Contact_Page_field Contact_Page_Name_Field">
        <input
          type={type}
          placeholder={placeholder}
          id={id}
          name={name}
          className="contact_page_input contact_input_fn"
          value={values?.[`${name}`]}
          onChange={(e) => handleChange(e)}
        />

        {errors?.[`${name}`] && touched?.[`${name}`] && (
          <div
            style={{ fontSize: 14, textAlign: "left" }}
            className="error_message"
          >
            {errors?.[`${name}`]}
          </div>
        )}
      </div>
    </>
  );
};
export default CommonInput;
