import Footer from "../../components/layout/Footer/footer";
import Header from "../../components/layout/Header/header";
import MachinesOneImg from "../../assets/img/machines_1.png";
import MachinesTwoImg from "../../assets/img/machines_2.png";
import "./dashboard.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { DashboardString } from "../../Utils/Constants";
import MachinesCard from "../../components/MachinesCard/machinesCard";
import { useEffect, useState } from "react";
import {
  getAllMachinesList,
  postMachinesList,
} from "../../services/machineService";
import MachineLogo from "../../assets/img/machines_2.png";
import Layout from "../../components/layout/layout";
import { ApplicationId } from "../../api/url";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [machinesList, setMachinesList] = useState([]);
  const [images, setImages] = useState([]);
  const [machinesTypeList, setMachinesTypeList] = useState([]);

  const GetMachinesList = () => {
    setIsLoading(true);
    let payload = {
      PageSize: 6,
      PageNumber: 1,
      IsFeatured: true,
      ApplicationId: ApplicationId,
    };
    postMachinesList(payload)
      .then((res) => {
        let showImgList = [];
        res?.data?.machines?.map?.((item, index) => {
          showImgList[index] = {
            ...showImgList[index],
            image: item?.primary_image?.url,
            // caption: `<div  class="bg-[#000] responsive_text_field" style="background: rgba(0,0,0,0.5); width: 90%; margin: auto; text-align:right; padding:0px 10px ">

            //   </div>`,
            caption: `
                                <div style="
                                    border-left: 7px solid #fff;
                                    padding-left: 10px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;"
                                >
                                    <div class="carousel_label"> ${
                                      item?.year
                                    }&nbsp;${
              item?.model?.manufacturer?.name
            }&nbsp;${item?.model?.number}</div>
                                    <div class="carousel_sub_label">${
                                      item?.meter_reading
                                        ? item.meter_reading
                                        : "N/A"
                                    } ${
              item?.meter_reading_unit ? item?.meter_reading_unit : "Hours"
            }</div>
                                </div>
                            `,
            assetAdvertisementId: item?.assetAdvertisementId,
            name: item?.primary_image?.name.replaceAll(" ", "-"),
            URL: `${item?.year}-${item?.model?.manufacturer?.name}-${
              item?.model?.number
            }${item?.unit_number ? `-${item?.unit_number}` : ``}`,
            category: item?.category,
          };
        });
        setImages(showImgList);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const GetMachinesTypeList = (val) => {
    getAllMachinesList()
      .then((res) => {
        const WithoutAttachmentsList = res?.data?.filter(
          (item) => item?.AssetTypeId !== 1
        );
        setMachinesTypeList(WithoutAttachmentsList);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    GetMachinesTypeList();
  }, []);

  useEffect(() => {
    GetMachinesList();
    if (window.location.href.split("userID=")?.[1]) {
      localStorage.setItem(
        "UserID",
        window.location.href.split("userID=")?.[1]
      );
    }
  }, []);

  const MachinesData = [
    {
      img: MachinesTwoImg,
      title: "10’ Track Pavers",
    },
    {
      img: MachinesTwoImg,
      title: "10’ Track Pavers",
    },
    {
      img: MachinesTwoImg,
      title: "10’ Track Pavers",
    },
    {
      img: MachinesTwoImg,
      title: "10’ Track Pavers",
    },
    {
      img: MachinesTwoImg,
      title: "10’ Track Pavers",
    },
    {
      img: MachinesTwoImg,
      title: "10’ Track Pavers",
    },
    {
      img: MachinesTwoImg,
      title: "10’ Track Pavers",
    },
    {
      img: MachinesTwoImg,
      title: "10’ Track Pavers",
    },
    {
      img: MachinesTwoImg,
      title: "10’ Track Pavers",
    },
  ];

  const GetRecentMachinesList = () => {
    // setIsLoading(true);
    let payload = {
      PageSize: 9,
      PageNumber: 1,
      IsLatestFirst: true,
      ApplicationId: ApplicationId,
    };
    postMachinesList(payload)
      .then((res) => {
        setMachinesList(res?.data);
        // setIsLoading(false);
      })
      .catch((err) => console.log(err));
    // setIsLoading(false);
  };

  useEffect(() => {
    GetRecentMachinesList();
  }, []);

  return (
    <>
      <Layout tab="home">
        <div className="container">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "30px",
                height: "60vh",
                alignItems: "center",
              }}
            >
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
          ) : (
            <div className="Carousel">
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                centerMode={false}
                showStatus={false}
                interval={5000}
              >
                {images?.map((item, index) => {
                  return (
                    <div
                      className="Carousel-img"
                      onClick={() => {
                        //     const encodeURICom = encodeURIComponent(
                        //         `${item.assetAdvertisementId}-${item?.name}`
                        //     );
                        //     history.push({
                        //         pathname: `/detail/${encodeURICom}`,
                        //         state: { id: item },
                        //     });
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        const encodeURICom = encodeURIComponent(item.URL);
                        navigate(
                          `/detail/${item?.category?.name.replace(
                            / /g,
                            "-"
                          )}/${encodeURICom}`,
                          { state: item }
                        );
                        window.location.reload();
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url("${item?.image}")`,
                          backgroundSize: "cover",
                        }}
                        className="carousel-img"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.caption,
                          }}
                          className="w-full mb-10 text-white"
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
        <div className="machine_list">
          <div className="machine_list_Header">
            <div className="container">
              {DashboardString.EQUIPMENT_CATEGORIES}
            </div>
          </div>

          <div className="container">
            <div className="Machines_Data_Lists">
              {machinesTypeList
                ?.find((card) => card.AssetTypeId === 5)
                ?.CategoryDetails.sort((a, b) =>
                  a.CategoryName.localeCompare(b.CategoryName)
                )
                .map((item) => {
                  return (
                    <MachinesCard
                      item={item}
                      title={item.CategoryName}
                      img={MachineLogo}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Dashboard;
