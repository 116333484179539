import { useNavigate } from "react-router-dom";
import "./equipmentCard.css";
import { Img } from "react-image";

export const MachineBoxUseCopy = ({
  notShowCheckBox,
  MachineDetails,
  selecedEquipment,
  setSelecedEquipment,
}) => {
  const navigate = useNavigate();


  const URL = `${MachineDetails?.year}-${MachineDetails?.model?.manufacturer?.name}-${MachineDetails?.model?.number
    }${MachineDetails?.unit_number ? `-${MachineDetails?.unit_number}` : ``}`


  const encodeURICom = encodeURIComponent(URL);
  const RPath = `/detail/${MachineDetails?.category?.name.replace(
    / /g,
    "-"
  )}/${encodeURICom}-${MachineDetails?.assetAdvertisementId}`;

  const imageUrl = MachineDetails?.primary_image?.url

  return (
    <>
      <table className="" style={{ width: "-webkit-fill-available" }}>
        <tr
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <td>
            <Img src={imageUrl} width={150} />
          </td>
          <td style={{ width: "-webkit-fill-available" }}>
            <table style={{ width: "-webkit-fill-available" }}>
              <tr>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    minHeight: "110px",
                  }}
                >
                  <table className="MachineList_Title_Box">
                    <tr>
                      <td
                        // style={{ fontWeight: "bold" }}
                        className="MachineList_Title MachineList_Title_Hover"
                      >
                        <a href={RPath} style={{ fontWeight: "600" }}>
                          {MachineDetails?.model?.manufacturer?.name}{" "}
                          {MachineDetails?.model?.number}{" "}
                          {MachineDetails?.year
                            ? `(${MachineDetails?.year})`
                            : null}{" "}
                          {MachineDetails?.unit_number
                            ? ` UNIT # ${MachineDetails?.unit_number}`
                            : null}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        // style={{ fontWeight: "bold" }}
                        className="MachineList_Title"
                      >
                        <a
                          href={RPath}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {MachineDetails.meter_reading
                            ? `MACHINE HOURS: ${MachineDetails.meter_reading}`
                            : `MACHINE HOURS: N/A`}
                        </a>
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td
                        className="MachineList_description"
                      // style={{ fontSize: "12px" }}
                      >
                        {MachineDetails.description}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: MachineDetails?.notes,
                          }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="MachineList_Title"
                        style={{ textAlign: "right", fontSize: "16px" }}
                      >
                        <a href={RPath} style={{ fontWeight: "400" }}>
                          {"Click here for more details and pricing"}
                        </a>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </>
  );
};

const EquipmentCard = ({
  Data,
  selecedEquipment,
  setSelecedEquipment,
  isMachineData,
  notShowCheckBox,
  selectedCheckList,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ position: "relative", display: "flex" }}>
        {!notShowCheckBox && (
          <div style={{ display: "flex", alignItems: "center" }}>
            {selectedCheckList && (
              <div
                style={{ marginBottom: "20px", width: "30px" }}
              >
                <input
                  type="checkbox"
                  className="checkbox"
                  style={{ marginRight: "10px" }}
                  checked={
                    selecedEquipment?.find((item) => item.id === Data.id) || false
                  }
                  onClick={(e) => {
                    if (e.target.checked) {
                      setSelecedEquipment([...selecedEquipment, Data]);
                    } else {
                      const newData = selecedEquipment?.filter(
                        (item) => item.id !== Data.id
                      );
                      setSelecedEquipment(newData);
                    }
                  }}
                />
              </div>
            )}
          </div>
        )}

        <div
          className="EquipmentCard"
          style={{ width: "-webkit-fill-available" }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            const encodeURICom = encodeURIComponent(
              `${Data?.year}-${Data?.model?.manufacturer?.name}-${Data?.model?.number}-${Data?.unit_number}`
            );
            navigate(
              `/detail/${Data?.category?.name.replace(
                / /g,
                "-"
              )}/${encodeURICom}`,
              { state: Data }
            );
            window.location.reload();
          }}
        >
          <div
            className="EquipmentCard_img"
            style={{
              backgroundImage: `url("${Data.primary_image.url}")`,
              backgroundSize: "cover",
              minHeight: "245px",
              minWidth: "326px",
            }}
          ></div>
          <div className="EquipmentCard_Data_box">
            <div className="EquipmentCard_title">
              <div className="EquipmentCard_Data_title">
                {Data?.year}&nbsp;{Data?.model?.manufacturer?.name}&nbsp;
                {Data?.model?.number}
              </div>
              <div className="EquipmentCard_sub_title">
                {Data.meter_reading ? Data.meter_reading : "N/A"}{" "}
                {Data?.meter_reading_unit ? Data?.meter_reading_unit : "Hours"}
              </div>
            </div>
            <div className="EquipmentCard_DETAIL_Box">
              <div className="EquipmentCard_DETAILS font-bold">DETAILS:</div>
              <div className="EquipmentCard_DETAILS EquipmentCard_DETAILS_Note">
                {Data?.notes || "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EquipmentCard;
