import Footer from "../../components/layout/Footer/footer";
import Header from "../../components/layout/Header/header";
import SearchKeywordMenu from "../../components/search_keyword_menu/searchKeywordMenu";
import MachinesOneImg from "../../assets/img/machines_1.png";
import "./equipment.css";
import EquipmentCard, {
  MachineBoxUseCopy,
} from "../../components/EquipmentCard/equipmentCard";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getAllMachinesList,
  postMachinesList,
} from "../../services/machineService";
import { useLocation } from "react-router-dom";
import CloseIcon from "../../assets/img/close-icon.png";
import Layout from "../../components/layout/layout";
import { ApplicationId } from "../../api/url";
import { Img } from "react-image";
import CopyIcon from "../../assets/img/copy.png";
import CheckList from "../../assets/svg/checkList.svg";

const Equipment = ({ AttachmentsListPage }) => {
  const { state } = useLocation();
  const [isMachineData, setIsMachineData] = useState([]);

  const [selectedCheckList, setSelectedCheckList] = useState(false);

  const [machineTypeSelectIdCategoryName, setMachineTypeSelectIdCategoryName] =
    useState("");
  const [page, setPage] = useState(1);
  const limit = 9;
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [SearchKey, setSearchKey] = useState(
    localStorage.getItem("searchFilter")
  );
  const [pageRefresh, setPageRefresh] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [assetTypeId, setAssetTypeId] = useState("");
  const [allFilterID, setAllFilterID] = useState(null);
  const [dataLoadLoading, setDataLoadLoading] = useState(true);
  const [categoryofMachineList, setCategoryofMachineList] = useState(
    state?.categorizatioList
  );
  // ------------------------ search bar code start --------------------
  const [AssetTypeList, setAssetTypeList] = useState([]);
  const [assetList, setAssetList] = useState({
    ShowTitle: "",
    selectAssetDetails: {},
    CategoryDetails: {},
    Tier1Details: [],
    Tier1Select: {},
    Tier2Details: [],
    Tier2Select: {},
    Tier3Details: [],
    Tier3Select: {},
    Tier4Details: [],
    Tier4Select: {},
    Tier5Details: [],
    Tier5Select: {},
    tierCount: 0,
    TierData: false,
    ShowBackBTN: false,
    ShowTitle: "",
  });
  const [fristTimeEnter, setFristTimeEnter] = useState(true);
  const [fristTimeEnterSucc, setFristTimeEnterSucc] = useState(false);
  const [
    assetCategorizationSummaryLoading,
    setAssetCategorizationSummaryLoading,
  ] = useState(false);
  const [machinesTypeList, setMachinesTypeList] = useState([]);
  const [selecedEquipment, setSelecedEquipment] = useState([]);
  const [copyMachine, setCopyMachine] = useState(false);

  // ------------------------ search bar code end--------------------

  useEffect(() => {
    if (fristTimeEnterSucc) {
      setFristTimeEnter(false);
    }
  }, [fristTimeEnterSucc]);

  useEffect(() => {
    if (
      !AttachmentsListPage &&
      machinesTypeList?.length !== 0 &&
      categoryofMachineList &&
      categoryofMachineList?.length !== 0 &&
      categoryofMachineList !== undefined
    ) {
      // ------------------ categoryofMachineList zero AssetTypeId ------------------
      // ------------------ categoryofMachineList One CategoryId --------------------
      // ------------------ categoryofMachineList two Tier1Id --------------------
      // ------------------ categoryofMachineList three Tier2Id --------------------
      // ------------------ categoryofMachineList four Tier3Id ------------------
      // ------------------ categoryofMachineList Five Tier4Id ------------------
      // ------------------ categoryofMachineList Six Category Name ------------------

      handleOnChangeTypePreviousPageDetails(
        `${categoryofMachineList[6] ?? null},${categoryofMachineList[0] ?? null
        },${categoryofMachineList[2] ?? null},${categoryofMachineList[3] ?? null
        },${categoryofMachineList[4] ?? null},${categoryofMachineList[1] ?? null
        }`
      );
    } else if (
      AttachmentsListPage &&
      machinesTypeList?.length !== 0 &&
      categoryofMachineList &&
      categoryofMachineList?.length !== 0 &&
      categoryofMachineList !== undefined &&
      !fristTimeEnter
    ) {
      setMachineTypeSelectIdCategoryName(categoryofMachineList[1] ?? "");
      GetMachinesList(categoryofMachineList[1] ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machinesTypeList, categoryofMachineList]);
  useEffect(() => {
    if (
      machinesTypeList?.length > 0 &&
      categoryofMachineList === undefined &&
      !isLoading &&
      !fristTimeEnter
    ) {
      GetMachinesList();
    }
  }, [pageNumber]);
  useEffect(() => {
    GetMachinesTypeList();
  }, []);
  const GetMachinesTypeList = (val) => {
    setAssetCategorizationSummaryLoading(true);
    getAllMachinesList()
      .then((res) => {
        if (AttachmentsListPage) {
          const AttachmentsList = res?.data?.filter(
            (item) => item?.AssetTypeId === 1
          );
          setMachinesTypeList(AttachmentsList);
          setAssetTypeList(AttachmentsList);
        } else {
          const WithoutAttachmentsList = res?.data?.filter(
            (item) => item?.AssetTypeId !== 1
          );
          const getSelectData = WithoutAttachmentsList.find(
            (item) => item.AssetTypeId === 5
          );
          if (fristTimeEnter && !fristTimeEnterSucc && state?.CategoryId) {
            setAssetList({
              ...assetList,
              selectAssetDetails: getSelectData,
              CategoryDetails: state,
            });
          } else {
            setAssetList({
              ...assetList,
              selectAssetDetails: getSelectData,
            });
          }

          setFristTimeEnterSucc(true);
          setAssetTypeList(WithoutAttachmentsList);
          setMachinesTypeList(WithoutAttachmentsList);
        }
        setAssetCategorizationSummaryLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const GetMachinesList = (val) => {
    if (isMachineData?.length === 0) {
      setIsLoading(true);
    }
    setDataLoadLoading(true);
    setCategoryofMachineList(undefined);
    let payload;

    if (AttachmentsListPage) {
      if (val) {
        payload = {
          AssetTypeId: 5,
          CategoryId: val,
          PageSize: limit,
          PageNumber: pageNumber,
          IsMachineListPage: false,
        };
      } else {
        payload = {
          AssetTypeId: 5,
          CategoryId: machineTypeSelectIdCategoryName
            ? JSON.parse(machineTypeSelectIdCategoryName)
            : machineTypeSelectIdCategoryName,
          PageSize: limit,
          PageNumber: pageNumber,
          IsMachineListPage: false,
        };
      }
    } else {
      if (allFilterID) {
        payload = {
          AssetTypeId: 5,
          PageSize: limit,
          PageNumber: pageNumber,
          IsMachineListPage: true,
          Tier1Id: JSON.parse(allFilterID[2]),
          Tier2Id: JSON.parse(allFilterID[3]),
          Tier3Id: JSON.parse(allFilterID[4]),
          CategoryId: JSON.parse(allFilterID[5]),
        };
      } else if (val) {
        // ------------------ Split zero CategoryName ------------------
        // ------------------ Split One AssetTypeId --------------------
        // ------------------ Split two Tier1Id --------------------
        // ------------------ Split three Tier2Id --------------------
        // ------------------ Split four Tier3Id ------------------
        // ------------------ Split five CategoryId ------------------
        const IDs = val?.split(",");
        payload = {
          AssetTypeId: 5,
          PageSize: limit,
          PageNumber: pageNumber,
          IsMachineListPage: true,
          Tier1Id: JSON.parse(IDs?.[2]) ?? null,
          Tier2Id: JSON.parse(IDs?.[3]) ?? null,
          Tier3Id: JSON.parse(IDs?.[4]) ?? null,
          CategoryId: JSON.parse(IDs?.[5]) ?? null,
        };
      } else {
        payload = {
          AssetTypeId: 5,
          PageSize: limit,
          PageNumber: pageNumber,
          IsMachineListPage: true,
        };
      }
    }
    payload = { ...payload, IsOldiesByGoodies: false };
    if (localStorage.getItem("searchFilter") !== null || SearchKey) {
      payload = {
        ...payload,
        PageNumber: pageNumber,
        SearchKey: localStorage.getItem("searchFilter")
          ? localStorage.getItem("searchFilter")
          : SearchKey,
      };
    }

    payload = {
      ...payload,
      ApplicationId: ApplicationId,
    };
    postMachinesList(payload)
      .then((res) => {
        const machineData = res && res.data;
        setTotalRecords(machineData?.TotalRecords);
        if (parseInt(machineData?.PageNumber) == 1) {
          setIsMachineData(machineData?.machines);
          setPage(machineData?.PageNumber);
        } else {
          setIsMachineData([...isMachineData, ...machineData?.machines]);
          setPage(machineData?.PageNumber);
        }
        setIsLoading(false);
        setDataLoadLoading(false);
        setFristTimeEnterSucc(true);
      })
      .catch((err) => console.log(err));
  };

  const GetMachinesListById = (val) => {
    console.log("dfsdsffsdfsdfsd--------------2", isMachineData)
    if (isMachineData?.length === 0) {
      setIsLoading(true);
    }
    setDataLoadLoading(true);
    setCategoryofMachineList(undefined);
    let payload;

    if (val) {
      // ------------------ Split zero CategoryName ------------------
      // ------------------ Split One AssetTypeId --------------------
      // ------------------ Split two Tier1Id --------------------
      // ------------------ Split three Tier2Id --------------------
      // ------------------ Split four Tier3Id ------------------
      // ------------------ Split five CategoryId ------------------
      const IDs = val?.split(",");
      payload = {
        AssetTypeId: 5,
        PageSize: limit,
        PageNumber: pageNumber,
        IsMachineListPage: true,
        Tier1Id: JSON.parse(IDs?.[2]) ?? null,
        Tier2Id: JSON.parse(IDs?.[3]) ?? null,
        Tier3Id: JSON.parse(IDs?.[4]) ?? null,
        CategoryId: JSON.parse(IDs?.[5]) ?? null,
      };
    }
    payload = { ...payload, IsOldiesByGoodies: false };
    if (localStorage.getItem("searchFilter") !== null || SearchKey) {
      payload = {
        ...payload,
        PageNumber: pageNumber,
        SearchKey: localStorage.getItem("searchFilter")
          ? localStorage.getItem("searchFilter")
          : SearchKey,
      };
    }

    payload = {
      ...payload,
      ApplicationId: ApplicationId,
    };
    postMachinesList(payload)
      .then((res) => {
        const machineData = res && res.data;
        setTotalRecords(machineData?.TotalRecords);
        if (parseInt(machineData?.PageNumber) == 1) {
          setIsMachineData(machineData?.machines);
          setPage(machineData?.PageNumber);
        } else {
          setIsMachineData([...isMachineData, ...machineData?.machines]);
          setPage(machineData?.PageNumber);
        }
        setIsLoading(false);
        setDataLoadLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const GetMachinesListByAsset = (val) => {
    console.log("dfsdsffsdfsdfsd--------------3")
    if (isMachineData?.length === 0) {
      setIsLoading(true);
    }
    setDataLoadLoading(true);
    setCategoryofMachineList(undefined);
    let payload;

    payload = {
      AssetTypeId: 5,
      PageSize: limit,
      PageNumber: pageNumber,
      CategoryId: val.CategoryId,
      IsMachineListPage: true,
    };
    payload = { ...payload, IsOldiesByGoodies: false };
    if (localStorage.getItem("searchFilter") !== null || SearchKey) {
      payload = {
        ...payload,
        PageNumber: pageNumber,
        SearchKey: localStorage.getItem("searchFilter")
          ? localStorage.getItem("searchFilter")
          : SearchKey,
      };
    }

    payload = {
      ...payload,
      ApplicationId: ApplicationId,
    };
    postMachinesList(payload)
      .then((res) => {
        const machineData = res && res.data;
        setTotalRecords(machineData?.TotalRecords);
        if (parseInt(machineData?.PageNumber) == 1) {
          setIsMachineData(machineData?.machines);
          setPage(machineData?.PageNumber);
        } else {
          setIsMachineData([...isMachineData, ...machineData?.machines]);
          setPage(machineData?.PageNumber);
        }

        setIsLoading(false);
        setDataLoadLoading(false);
        setFristTimeEnterSucc(true);
      })
      .catch((err) => console.log(err));
  };

  const handleOnChangeType = (e) => {
    // ------------------ Split zero CategoryName ------------------
    // ------------------ Split One AssetTypeId --------------------
    // ------------------ Split two Tier1Id --------------------
    // ------------------ Split three Tier2Id --------------------
    // ------------------ Split four Tier3Id ------------------
    // ------------------ Split five CategoryId ------------------
    setIsMachineData([]);
    setPage(1);
    if (e !== "") {
      const IDs = e.split(",");
      setPageNumber(1);
      setAllFilterID(IDs);
      setMachineTypeSelectIdCategoryName(IDs[0]);
      if (IDs[1] !== undefined) {
        setAssetTypeId(IDs[1]);
      }
    } else {
      setAllFilterID(null);
      setMachineTypeSelectIdCategoryName("");
      setAssetTypeId("");
    }
  };
  const handleOnChangeTypePreviousPageDetails = (e) => {
    // ------------------ Split zero CategoryName ------------------
    // ------------------ Split One AssetTypeId --------------------
    // ------------------ Split two Tier1Id --------------------
    // ------------------ Split three Tier2Id --------------------
    // ------------------ Split four Tier3Id ------------------
    // ------------------ Split five CategoryId ------------------
    const IDs = e.split(",");
    setPageNumber(1);
    setAllFilterID(IDs);
    setMachineTypeSelectIdCategoryName(IDs[0]);
    setAssetTypeId(IDs[1]);
    if (!fristTimeEnter) {
      GetMachinesList(e);
    }
  };
  const handleRemoveSearchFilter = () => {
    window.location.reload();
    localStorage.removeItem("searchFilter");
  };

  // --------------- search bar code start ----------------

  const handelSelectAssetTypeList = async (item, Type) => {
    // resetPagination();
    if (Type?.Type === "AssetList") {
      setAssetList({
        ...assetList,
        ShowTitle: item?.AssetTypeName,
        selectAssetDetails: item,
        CategoryDetails: {},
      });
      handleOnChangeType(
        `${null},${item?.AssetTypeId},${null},${null},${null},${null}`
      );

      GetMachinesListById(
        `${null},${item?.AssetTypeId},${null},${null},${null},${null}`
      );
    } else if (Type?.Type === "CategoryDetails") {
      handleOnChangeType(
        `${item?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId
        },${null},${null},${null},${item?.CategoryId}`
      );

      GetMachinesListById(
        `${item?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId
        },${null},${null},${null},${item?.CategoryId}`
      );
      return setAssetList({
        ...assetList,
        CategoryDetails: item,
        ShowTitle: item?.CategoryName,
        Tier1Details: item?.Tier1Details,
        tierCount: assetList?.tierCount + 1,
        TierData: true,
      });
    } else if (Type?.Type === "TierData") {
      if (
        item?.[`Tier${assetList?.tierCount + 1}Details`] !== undefined &&
        item?.[`Tier${assetList?.tierCount + 1}Details`]?.length !== 0
      ) {
        switch (assetList?.tierCount) {
          case 0:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId
              },${null},${null},${null},${assetList?.CategoryDetails?.CategoryId
              }`
            );
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 1:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId
              },${item?.Tier1Id},${null},${null},${assetList?.CategoryDetails?.CategoryId
              }`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 2:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId
              },${assetList?.Tier1Select?.Tier1Id},${item?.Tier2Id},${null},${assetList?.CategoryDetails?.CategoryId
              }`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 3:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId},${assetList?.Tier1Select?.Tier1Id},${assetList?.Tier2Select?.Tier2Id},${item?.Tier3Id},${assetList?.CategoryDetails?.CategoryId}`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          default:
            return;
        }
      } else {
        switch (assetList?.tierCount) {
          case 0:
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              TierData: true,
            });
          case 1:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              TierData: true,
            });
          case 2:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              TierData: true,
            });
          case 3:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId},${assetList?.Tier1Select?.Tier1Id},${assetList?.Tier2Select?.Tier2Id},${item?.Tier3Id},${assetList?.CategoryDetails?.CategoryId}`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              TierData: true,
            });
          default:
            return;
        }
      }
    }
  };
  const handelSelectAssetTypeListBackFun = (val) => {
    const tierCountNumber = val ? val : assetList?.tierCount;

    if (val === 1) {
      GetMachinesListById(
        `${null},${assetList?.selectAssetDetails?.AssetTypeId
        },${null},${null},${null},${null}`
      );
    } else if (val === 2) {
      GetMachinesListById(
        `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId
        },${null},${null},${null},${assetList?.CategoryDetails?.CategoryId}`
      );
    } else if (!val) {
      if (assetList?.CategoryDetails?.CategoryId) {
        GetMachinesListById(
          `${null},${assetList?.selectAssetDetails?.AssetTypeId
          },${null},${null},${null},${null}`
        );
      } else if (assetList?.selectAssetDetails?.AssetTypeId) {
        resetAllFilter();
      }
    }

    if (
      assetList?.selectAssetDetails &&
      Object.keys(assetList?.selectAssetDetails).length !== 0 &&
      assetList?.tierCount === 0
    ) {
      setAssetList({
        ...assetList,

        ShowTitle: "",
        selectAssetDetails: {},
        ShowBackBTN: false,
      });
      handleOnChangeType(`${null},${null},${null},${null},${null},${null}`);
    } else if (tierCountNumber !== 0) {
      switch (tierCountNumber) {
        case 0:
          return setAssetList({
            ...assetList,
            ShowTitle: "",
            CategoryDetails: {},
            Tier1Select: {},
            Tier1Details: [],
            TierData: false,
          });
        case 1:
          handleOnChangeType(
            `${null},${assetList?.selectAssetDetails?.AssetTypeId
            },${null},${null},${null},${null}`
          );
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.selectAssetDetails?.AssetTypeName,
            Tier1Select: {},
            CategoryDetails: {},
            Tier2Details: [],
            tierCount: tierCountNumber - 1,
            TierData: false,
          });
        case 2:
          handleOnChangeType(
            `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId
            },${null},${null},${null},${assetList?.CategoryDetails?.CategoryId}`
          );
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.CategoryDetails?.CategoryName,
            Tier2Select: {},
            Tier1Select: {},
            Tier3Details: [],
            tierCount: tierCountNumber - 1,
            TierData: true,
          });
        case 3:
          handleOnChangeType(
            `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId
            },${assetList?.Tier1Select?.Tier1Id},${null},${null},${assetList?.CategoryDetails?.CategoryId
            }`
          );
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier1Select?.Tier1Name,
            Tier3Select: {},
            Tier2Select: {},
            Tier4Details: [],
            tierCount: tierCountNumber - 1,
            TierData: true,
          });
        case 4:
          handleOnChangeType(
            `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId
            },${assetList?.Tier1Select?.Tier1Id},${assetList?.Tier2Select?.Tier2Id
            },${null},${assetList?.CategoryDetails?.CategoryId}`
          );
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier2Select?.Tier2Name,
            Tier4Select: {},
            Tier3Select: {},
            Tier5Details: [],
            tierCount: tierCountNumber - 1,
            TierData: true,
          });

        case 5:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier3Select?.Tier3Name,
            Tier4Select: {},
            Tier6Details: [],
            tierCount: tierCountNumber - 1,
            TierData: true,
          });
        default:
          return;
      }
    }
  };
  const resetAllFilter = () => {
    setAssetList({
      ...assetList,
      ShowTitle: "",
      CategoryDetails: {},
      tierCount: 0,
      TierData: false,
      ShowBackBTN: false,
      ShowTitle: "",
    });
    handleOnChangeType(`${null},${null},${null},${null},${null},${null}`);

    GetMachinesListById(`${null},${null},${null},${null},${null},${null}`);
  };

  const componentCopyRef = useRef();

  const Copy = () => {
    const range = document.createRange();
    range.selectNode(componentCopyRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  };

  // --------------- search bar code end ----------------
  useEffect(() => {
    if (
      !isLoading &&
      isMachineData.length == 0 &&
      state?.CategoryId &&
      fristTimeEnter
    )
      handelSelectAssetTypeList(state, {
        Type: "CategoryDetails",
      });
  }, [isLoading, state]);

  useEffect(() => {
    setPageNumber(1);
    setSearchKey(localStorage.getItem("searchFilter"));
    if (state?.CategoryId && fristTimeEnter) {
      GetMachinesListByAsset(state);
    } else {
      if (pageNumber === 1) {
        GetMachinesList();
      }
    }
  }, [localStorage.getItem("searchFilter"), pageRefresh]);

  useEffect(() => {
    if (copyMachine) {
      Copy();
    }
  }, [copyMachine]);

  return (
    <>
      <Layout pageRefresh={pageRefresh} setPageRefresh={setPageRefresh}>
        <div className="container">
          {copyMachine ? (
            <div className="dashboard responsive_dashboard" style={{ marginTop: '20px' }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "50vh",
                }}
              >
                <div
                  style={{
                    marginRight: 10,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src={CopyIcon}
                    alt=""
                    style={{ width: "25px", marginBottom: '10px' }}
                    onClick={() => setCopyMachine(!copyMachine)}
                  />
                </div>
                <table
                  ref={componentCopyRef}
                  style={{
                    minHeight: "50vh",
                  }}
                >
                  <tbody>
                    {selecedEquipment.map((MachineDetails, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ width: "-webkit-fill-available" }} valign="top">
                            <MachineBoxUseCopy
                              MachineDetails={MachineDetails}
                              notShowCheckBox
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <>
              {localStorage.getItem("searchFilter") !== undefined &&
                localStorage.getItem("searchFilter") !== "" &&
                localStorage.getItem("searchFilter") !== null ? (
                <div className="about_inventory_btn_filter_key_word">
                  Search Keyword : {localStorage.getItem("searchFilter")}
                  <img
                    src={CloseIcon}
                    alt=""
                    className="about_inventory_btn_filter_icon"
                    onClick={handleRemoveSearchFilter}
                  />
                </div>
              ) : null}
              <div className="Equipment_Grid">
                {isLoading && isMachineData.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70vh",
                    }}
                  >
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                ) : (
                  <div className="EquipmentDataList">
                    <div className="Equipment_bird_crump Equipment_bird_crump_equipment">
                      {
                        <>
                          <div>
                            <span
                              className="Equipment_bird_crump_title"
                              onClick={() => resetAllFilter()}
                            >
                              EQUIPMENT
                            </span>

                            {assetList?.CategoryDetails?.CategoryName && (
                              <>
                                <span className="Equipment_bird_crump_title_Arrow">
                                  {" > "}
                                </span>
                                <span
                                  className="Equipment_bird_crump_title"
                                  onClick={() =>
                                    handelSelectAssetTypeListBackFun(2)
                                  }
                                >
                                  {assetList?.CategoryDetails?.CategoryName}
                                </span>
                              </>
                            )}
                            {assetList?.Tier1Select?.Tier1Name && (
                              <>
                                <span className="Equipment_bird_crump_title_Arrow">
                                  {" > "}
                                </span>
                                <span
                                  className="Equipment_bird_crump_title"
                                  onClick={() =>
                                    handelSelectAssetTypeListBackFun(3)
                                  }
                                >
                                  {assetList?.Tier1Select?.Tier1Name}
                                </span>
                              </>
                            )}
                            {assetList?.Tier2Select?.Tier2Name && (
                              <>
                                <span className="Equipment_bird_crump_title_Arrow">
                                  {" > "}
                                </span>
                                <span
                                  className="Equipment_bird_crump_title"
                                  onClick={() =>
                                    handelSelectAssetTypeListBackFun(4)
                                  }
                                >
                                  {assetList?.Tier2Select?.Tier2Name}
                                </span>
                              </>
                            )}
                            {assetList?.Tier3Select?.Tier3Name && (
                              <>
                                <span className="Equipment_bird_crump_title_Arrow">
                                  {" > "}
                                </span>
                                <span className="Equipment_bird_crump_title">
                                  {assetList?.Tier3Select?.Tier3Name}
                                </span>
                              </>
                            )}
                            {selectedCheckList && (
                              <div
                                style={{
                                  display: "flex",
                                  fontWeight: "bold",
                                  alignItems: "center",
                                  position: "relative",
                                  marginTop: 20,
                                  marginLeft: 5,
                                }}
                              >
                                <input
                                  className="checkbox"
                                  type="checkbox"
                                  style={{
                                    // position: "absolute",
                                    // left: "-26px",
                                  }}
                                  checked={
                                    isMachineData.length !== 0 &&
                                    selecedEquipment.length ===
                                    isMachineData.length
                                  }
                                  onChange={() => {
                                    if (
                                      selecedEquipment.length ===
                                      isMachineData.length
                                    ) {
                                      setSelecedEquipment([]);
                                    } else {
                                      setSelecedEquipment(isMachineData);
                                    }
                                  }}
                                />
                                SELECT ALL
                              </div>
                            )}
                          </div>

                          <div
                            // ml={"10px"}
                            style={{
                              cursor: "pointer",
                              justifyContent: "flex-end",
                            }}
                          // className="d-flex"
                          >

                          </div>
                        </>
                      }
                      <div className="sm_SearchKeywordMenu">
                        {selecedEquipment.length > 0 &&
                          selectedCheckList && (
                            <img
                              src={CopyIcon}
                              alt=""
                              style={{ width: "25px", marginRight: "15px" }}
                              onClick={() => setCopyMachine(!copyMachine)}
                            />
                          )}
                        <img
                          src={CheckList}
                          alt=""
                          width={"20px"}
                          style={{ width: "20px", marginRight: "15px" }}
                          onClick={() =>
                            setSelectedCheckList(!selectedCheckList)
                          }
                        />
                        <SearchKeywordMenu
                          backButton={true}
                          assetList={assetList}
                          AssetTypeList={AssetTypeList}
                          handelSelectAssetTypeList={handelSelectAssetTypeList}
                          handelSelectAssetTypeListBackFun={
                            handelSelectAssetTypeListBackFun
                          }
                          keyWordList={machinesTypeList}
                          assetCategorizationSummaryLoading={
                            assetCategorizationSummaryLoading
                          }
                        />
                      </div>
                    </div>

                    {isMachineData?.length > 0 ? (
                      <InfiniteScroll
                        dataLength={isMachineData.length}
                        next={() =>
                          totalRecords >= isMachineData?.length
                            ? setPageNumber(page + 1)
                            : null
                        }
                        hasMore={true}
                        loader={null}
                      >
                        {isMachineData.map((item) => {
                          return (
                            <EquipmentCard
                              Data={item}
                              selecedEquipment={selecedEquipment}
                              setSelecedEquipment={setSelecedEquipment}
                              isMachineData={isMachineData}
                              setSelectedCheckList={setSelectedCheckList}
                              selectedCheckList={selectedCheckList}
                            />
                          );
                        })}
                      </InfiniteScroll>
                    ) : (

                      !dataLoadLoading ? <div
                        className=" flex justify-center items-center"
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "30px",
                          marginBottom: "30px",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div className="No_Record">{dataLoadLoading}No Record found</div>
                      </div> : null
                    )}
                    {dataLoadLoading && (
                      <div
                        className=" flex justify-center items-center"
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "30px",
                          height: "40vh",
                          alignItems: "center",
                          marginBottom: "30px",
                        }}
                      >
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="big_SearchKeywordMenu">
                  <div style={{ marginTop: "30px", marginBottom: "15px", textAlign: "end" }}>
                    {selecedEquipment.length > 0 &&
                      selectedCheckList && (
                        <img
                          src={CopyIcon}
                          alt=""
                          style={{ width: "25px", marginRight: "15px" }}
                          onClick={() => setCopyMachine(!copyMachine)}
                        />
                      )}
                    <img
                      src={CheckList}
                      alt=""
                      style={{ width: "25px", marginRight: "15px" }}
                      onClick={() =>
                        setSelectedCheckList(!selectedCheckList)
                      }
                    />
                  </div>
                  <SearchKeywordMenu
                    backButton={true}
                    assetList={assetList}
                    AssetTypeList={AssetTypeList}
                    handelSelectAssetTypeList={handelSelectAssetTypeList}
                    handelSelectAssetTypeListBackFun={
                      handelSelectAssetTypeListBackFun
                    }
                    keyWordList={machinesTypeList}
                    assetCategorizationSummaryLoading={
                      assetCategorizationSummaryLoading
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Layout >
    </>
  );
};
export default Equipment;
