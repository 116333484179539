import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  FooterString,
  ReCAPTCHA_sitekey,
  header,
} from "../../../Utils/Constants";
import logoIeda from "../../../assets/img/ieda.png";
import napaImage from "../../../assets/img/napaImage.jpg";
import FBLogo from "../../../assets/img/FBLogo.png";
import LinkedinLogo from "../../../assets/img/linkedin_logo.png";
import "./footer.css";
import CustomButton from "../../custom_components/CustomButton";
import CommonModal from "../../common/CommonModal";
import { Formik } from "formik";
import * as Yup from "yup";
import CommonInput from "../../common/CommonInput";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { lead } from "../../../services/leadService";
import { ApplicationId } from "../../../api/url";

const Footer = ({ openModal }) => {
  const [open, setOpen] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [isFormLoading, setIsFormLoading] = useState(false);

  const reCaptchaRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const AccSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter Valid Email Address")
      .required("Please Enter E-mail Address"),
    firstName: Yup.string().required("Please Enter First Name"),
    lastName: Yup.string().required("Please Enter Last Name"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });


  const handleSubmitLead = (data, resetForm) => {
    setIsFormLoading(true);
    let payload = {
      FirstName: data?.firstName,
      LastName: data?.lastName,
      Email: data?.email,
      ReCaptchaResponse: reCaptchaRef?.current.getValue(),
      ApplicationId:ApplicationId
    };

    lead(payload)
      .then((res) => {
        setIsFormLoading(false);
        toast.success(res?.data?.message);
        resetForm();
        handleClose();
        reCaptchaRef?.current?.reset();
        setEmailInput("")
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setIsFormLoading(false);
      });
  }

  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="footer_grid">
            <div className="footer_logo_grid">
              <div className="footer_logo_text">
                {FooterString.PROUD_MEMBERS_OF}
              </div>

              <div className="image_container">
                <div>
                  <img src={logoIeda} alt="" className="logo_ieda" />
                </div>

                <div>
                  <img src={napaImage} alt="" className="napa_image" />
                </div>
              </div>
            </div>

            <div>
              <a
                href={`tel:${FooterString.NUMBER}`}
                className="footer_contact_link"
              >
                <div>{FooterString.NUMBER}</div>
              </a>
              <a
                href={`mailto:${FooterString.EMAIL}`}
                className="footer_contact_link"
              >
                <div>{FooterString.EMAIL}</div>
              </a>
            </div>

            <div className="footer_page_grid">
              <Link
                to="/equipment"
                className="footer_page_link"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  })
                }
              >
                <div className="footer_page_link">{header.Equipment}</div>
              </Link>
              <Link
                to="/about"
                className="footer_page_link"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  })
                }
              >
                {" "}
                <div className="footer_page_link">{header.About}</div>
              </Link>
              <Link
                to="/contact"
                className="footer_page_link"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  })
                }
              >
                {" "}
                <div className="footer_page_link">{header.Contact}</div>
              </Link>

              <div className="footer_s_l">
                <div className="footer_s_l_img">
                  <img src={FBLogo} width={"100%"} />
                </div>
                <div className="footer_s_l_img">
                  <img src={LinkedinLogo} width={"100%"} />
                </div>
              </div>
            </div>

            <div>
              <div className="footer_subscribe_box footer_subscribe_input">
                <input
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                  type="text"
                  placeholder="Type your email address..."
                  className="footer_input_box"
                />
                <CustomButton
                  lable={header.Subscribe}
                  onClick={handleOpen}
                  CustomButtonClass={"CONTACT_btn_Yellow_Color"}
                />
              </div>
            </div>
          </div>
        </div>

        <CommonModal isOpen={open} onClose={handleClose}>
          <Formik
            initialValues={{
              email: emailInput,
              firstName: "",
              lastName: "",
              reCaptcha: "",
            }}
            validationSchema={AccSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmitLead(values, resetForm)
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="contact_grid_box_top contact_grid_box_top_name">
                  <CommonInput
                    title="First Name"
                    values={values}
                    placeholder={"First name"}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    id="firstName"
                    name="firstName"
                    type="firstName"
                  />


                  <CommonInput
                    title="Last Name"
                    values={values}
                    placeholder={"Last name"}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    id="lastName"
                    name="lastName"
                    type="lastName"
                  />
                </div>

                <div className="Contact_Page_field Contact_Page_Name_Field">
                  <input
                    type="text"
                    placeholder={"Email"}
                    id="email"
                    name="email"
                    className="contact_page_input contact_input_fn"
                    value={values.email}
                    onChange={(e) => handleChange(e)}
                  />

                  {errors?.email && touched?.email && (
                    <div
                      style={{ fontSize: 14, textAlign: "left" }}
                      className="error_message"
                    >
                      {errors?.email}
                    </div>
                  )}
                </div>



                <div className="ReCAPTCHA_Box">
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    size="normal"
                    sitekey={ReCAPTCHA_sitekey}
                    onChange={(captchaCode) => {
                      setFieldValue("reCaptcha", captchaCode);
                    }}
                  />
                  {errors?.reCaptcha && touched?.reCaptcha && (
                    <div
                      style={{ fontSize: 14, textAlign: "left", marginTop: 10 }}
                      className="error_message"
                    >
                      {errors?.reCaptcha}
                    </div>
                  )}
                </div>

                <div className="buttonStyle">

                  <div style={{ marginRight: "10px" }}>
                    {isFormLoading ?
                      <button className={`loader-button loading CONTACT_btn_Yellow_Color_loading`} disabled>
                        <div className="loading-spinner-sm"></div>
                      </button>
                      :
                      <CustomButton
                        lable={header.Submit}
                        CustomButtonClass={"CONTACT_btn_Yellow_Color"}
                      />
                    }
                  </div>

                  <div style={{ marginLeft: "10px" }}>
                    <CustomButton
                      lable={header.Cancel}
                      CustomButtonClass={"Cancel_btn_Yellow_Color"}
                      onClick={handleClose}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </CommonModal>
      </div>
    </>
  );
};
export default Footer;
