import { Carousel } from "react-responsive-carousel";
import Footer from "../../components/layout/Footer/footer";
import Header from "../../components/layout/Header/header";
import SearchKeywordMenu from "../../components/search_keyword_menu/searchKeywordMenu";
import MachinesOneImg from "../../assets/img/machines_1.png";
import "./detail.css";
import { DetailString } from "../../Utils/Constants";
import CustomButton from "../../components/custom_components/CustomButton";
import { useEffect, useState } from "react";
import { getMachineDetailsByID } from "../../services/machineService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/layout/layout";
import { ApplicationId } from "../../api/url";
import { postContactUs } from "../../services/contactUsService";
import { toast } from "react-toastify";

const numberWithUSDFormat = (number) => {
  // Format number as USD
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};

const Detail = (props) => {
  const { state } = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);

  const [sendData, setSendData] = useState({
    emailCopy: false,
    email: null,
    dec: "",
    emailValidation: false,
  });
  const navigate = useNavigate();

  const keyWordList = [
    {
      key: "10’ Track Pavers",
    },
    {
      key: "10’ Rubber Tired Pavers",
    },
    {
      key: "8’ Track Pavers",
    },
    {
      key: "8’ Rubber Tired Pavers",
    },
    {
      key: "Asphalt Distributors",
    },
    {
      key: "Brooms & Sweepers",
    },
    {
      key: "Chip Spreaders",
    },
    {
      key: "Compaction",
    },
    {
      key: "Concrete",
    },
    {
      key: "Force Feed Loaders",
    },
    {
      key: "Material Transfer Vehicles",
    },
    {
      key: "Milling Machines",
    },
    {
      key: "Misc.",
    },
    {
      key: "Reclaimers & Stabilizers",
    },
    {
      key: "Road Wideners",
    },
  ];

  const getMachineDetails = () => {
    setIsLoading(true);
    const decode = decodeURIComponent(props?.match?.params?.id);
    let id = decode.split("-");
    getMachineDetailsByID(
      state?.assetAdvertisementId ||
        params?.id?.split("-")?.[params?.id?.split("-")?.length - 1]
    )
      .then((res) => {
        let showImgList = [];

        const res_img_info = res?.data?.images;

        const primary_image_info = res?.data?.primary_image;
        primary_image_info.order = -1;
        res_img_info.push(primary_image_info);

        const sortedData = res_img_info.sort((a, b) => a?.order - b?.order);

        sortedData?.map?.((item, index) => {
          showImgList[index] = {
            ...showImgList[index],
            image: item?.url,
            caption: ``,
            assetAdvertisementId: item?.assetAdvertisementId,
            name: item?.primary_image?.name.replaceAll(" ", "-"),
          };
        });

        setImages(showImgList);
        setData(res?.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handelCategorizationBreadcrumb = (e, categorization) => {
    const categorizatioList = e;
    navigate(`/equipment`, {
      state: categorizatioList,
    });
  };

  useEffect(() => {
    getMachineDetails();

    const scrollToElement = () => {
      const element = document.getElementById("equip-detail");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToElement();
  }, []);

  const handleSubmitForm = () => {
    if (sendData.emailValidation) {
      let showImgList = [];

      const res_img_info = data?.images;
      const sortedData = res_img_info.sort((a, b) => a?.order - b?.order);

      sortedData?.map?.((item, index) => {
        showImgList[index] = {
          ...showImgList[index],
          image: item?.url,
          caption: ``,
          assetAdvertisementId: item?.assetAdvertisementId,
          name: item?.primary_image?.name.replaceAll(" ", "-"),
        };
      });

      const imageUrls =
        showImgList?.slice(0, 6)?.map((item) => item?.image) || [];
      const rows = [];
      for (let i = 0; i < imageUrls.length; i += 3) {
        rows.push(imageUrls.slice(i, i + 3));
      }

      const imagesHTML = rows
        .map((row, rowIndex) => {
          const colsHTML = row
            .map((url, colIndex) => {
              return `
          <td key="${colIndex}" style="padding: 5px; width: 33.33%;">
      <p class="MsoNormal" align="center" style="text-align:center;margin:0px">
      <img
      border="0" width="200" height="150"
      id="m_1689582008497171435m_-4042550376196605667m_1350759756584943885_x0000_i1031"
      src="${url}"
      style="outline:0px;width:2.0833in;height:1.5666in"
      alt="img-${rowIndex}-${colIndex}"
      class="CToWUd a6T" data-bit="iit" tabindex="0">
  <div class="a6S" dir="ltr"
    style="opacity: 0.01; left: 429.612px; top: 1038.69px;"><span
      data-is-tooltip-wrapper="true" class="a5q"
      jsaction="JIbuQc:.CLIENT">
      
          `;
            })
            .join("");

          return `<tr key="${rowIndex}">${colsHTML}</tr>`;
        })
        .join("");

      const htmlForm = `
      <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:full;background:rgb(247,247,247)">
        <tbody>
            <tr>
                <td valign="top" style="padding:0cm;border-radius:0px">
                    <div align="center">
                        <table border="0" cellspacing="0" cellpadding="0" width="622" style="width:466.5pt">
                            <tbody>
                                <tr>
                                    <td valign="top" style="padding:11.25pt 7.5pt">
                                        <div align="center">
                                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                                style="width:622.375px">
                                                <tbody>
                                                    <tr>
                                                        <td valign="top"
                                                            style="border:1pt solid rgb(247,247,247);background-image:initial;background-position:initial;background-size:initial;background-repeat:initial;background-origin:initial;background-clip:initial;padding:0cm">
                                                            <div align="center">
                                                                <table border="0" cellspacing="0" cellpadding="0"
                                                                    width="100%"
                                                                    style="width:620.775px;background:white">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="100%" valign="top"
                                                                                style="width:620.775px;padding:0cm">
                                                                                <div align="center">
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="width:620.775px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td valign="top"
                                                                                                    style="padding:0cm 15pt">
                                                                                                    <p class="MsoNormal"
                                                                                                        align="center"
                                                                                                        style="text-align:center">
                                                                                                        <img border="0"
                                                                                                            width="412"
                                                                                                            height="116"
                                                                                                            id="m_1689582008497171435m_-4042550376196605667m_1350759756584943885_x0000_i1033"
                                                                                                            src="https://ci3.googleusercontent.com/meips/ADKq_NaKrBVs99tb8lpIkkruNNbydMvxkuHfkqphWJtLi1IoZBDJB9UAhRbMeqqWX9cRZ0iAcYXvv260F6Oah3qD74UiLNvjBy4Ccx8Aunm0c2EMEWrKbcsg-X1KvovlNFER3-9ax1sPhsW0dJuxlw=s0-d-e1-ft#https://files.constantcontact.com/3a3204c1801/c6c40810-28a0-480f-a129-74bfb0d90db4.png"
                                                                                                            style="outline:0px;width:4.2916in;height:1.2083in"
                                                                                                            class="CToWUd a6T"
                                                                                                            data-bit="iit"
                                                                                                            tabindex="0">
                                                                                                    <div class="a6S"
                                                                                                        dir="ltr"
                                                                                                        style="opacity: 0.01; left: 726px;">
                                                                                                    <u></u><u></u></p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div align="center" style="padding:11.25pt 7.5pt">
                                                                <table border="0" cellspacing="0" cellpadding="0"
                                                                    width="100%" style="width:620.775px">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="100%" valign="top"
                                                                                style="width:620.775px;padding:0cm">
                                                                                <div align="center">
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="width:620.775px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td valign="top"
                                                                                                    style="padding:0cm">
                                                                                                    <p class="MsoNormal"
                                                                                                        align="center"
                                                                                                        style="text-align:center">
                                                                                                        <img border="0"
                                                                                                            width="602"
                                                                                                            height="451"
                                                                                                            id="m_1689582008497171435m_-4042550376196605667m_1350759756584943885_x0000_i1032"
                                                                                                            src=${
                                                                                                              data
                                                                                                                ?.primary_image
                                                                                                                ?.url
                                                                                                            }
                                                                                                            style="outline:0px;width:6.2666in;height:4.7in"
                                                                                                            class="CToWUd a6T"
                                                                                                            data-bit="iit"
                                                                                                            tabindex="0">
                                                                                                    <u></u><u></u></p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <p class="MsoNormal" align="center"
                                                                style="text-align:center"></p>
                                                            <div align="center" style="padding:0 0 11.25pt 0">
                                                                <table border="0" cellspacing="0" cellpadding="0"
                                                                    width="100%"
                                                                    style="width:620.775px;background:white">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="60%" valign="top"
                                                                                style="width:372.462px;padding:0cm">
                                                                                <div align="center" >
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="width:372.462px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td valign="top"
                                                                                                    style="padding:10pt 10pt">
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <b><span
                                                                                                                style="font-size:16.5pt;font-family:Roboto;color:rgb(89,12,25)">${
                                                                                                                  data?.year
                                                                                                                }&nbsp;${
        data?.model?.manufacturer?.name
      }&nbsp;${data?.model?.number}</span></b><b><span
                                                                                                                style="font-family:Roboto;color:rgb(89,12,25)"><u></u><u></u></span></b>
                                                                                                    </p>
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <span
                                                                                                            style="font-family:Roboto;color:rgb(89,12,25)">${
                                                                                                              data
                                                                                                                ?.category
                                                                                                                ?.name
                                                                                                            }
                                                                                                            <b><u></u><u></u></b></span>
                                                                                                    </p>
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <span
                                                                                                            style="font-family:Roboto;color:rgb(89,12,25)">${
                                                                                                              data?.meter_reading
                                                                                                            }
                                                                                                            ${
                                                                                                              data?.meter_reading_unit
                                                                                                            }<b><u></u><u></u></b></span>
                                                                                                    </p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <p class="MsoNormal" align="center"
                                                                                    style="text-align:center"></p>
                                                                                <div align="center">
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="width:372.462px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td valign="top"
                                                                                                    style="padding:7.5pt 15pt">
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <b><span
                                                                                                                style="font-family:Roboto;color:rgb(64,63,66)">DETAILS:</span></b><span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)"><u></u><u></u></span>
                                                                                                    </p>
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66);white-space: pre-line;">${
                                                                                                              data?.notes
                                                                                                            }<u></u><u></u></span>
                                                                                                    </p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                            <td width="40%" valign="top"
                                                                                style="width:248.312px;background:rgb(233,233,233);padding:0cm">
                                                                                <div>
                                                                                    <p class="MsoNormal" align="center"
                                                                                        style="text-align:center;line-height:15pt">
                                                                                    </p>
                                                                                </div>
                                                                                <div align="center">
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="width:248.312px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td valign="top"
                                                                                                    style="padding:7.5pt 15pt">
                                                                                                    <p align="center"
                                                                                                        style="margin:0cm;text-align:center">
                                                                                                        <b><span
                                                                                                                style="font-size:${
                                                                                                                  data
                                                                                                                    ?.alternate_price
                                                                                                                    ?.fractional &&
                                                                                                                  data
                                                                                                                    ?.alternate_price
                                                                                                                    ?.fractional !==
                                                                                                                    "0"
                                                                                                                    ? "30pt"
                                                                                                                    : "15pt"
                                                                                                                };font-family:Roboto;color:rgb(38,38,38)">
                                                                                                                
                                                                                                                ${
                                                                                                                  data
                                                                                                                    ?.alternate_price
                                                                                                                    ?.fractional
                                                                                                                    ? data
                                                                                                                        ?.alternate_price
                                                                                                                        ?.fractional ===
                                                                                                                      "0"
                                                                                                                      ? "Price will be provided"
                                                                                                                      : ` ${
                                                                                                                          data
                                                                                                                            ?.alternate_price
                                                                                                                            ?.currency
                                                                                                                            ?.symbol
                                                                                                                        } ${numberWithUSDFormat(
                                                                                                                          data
                                                                                                                            ?.alternate_price
                                                                                                                            ?.fractional
                                                                                                                        ).replace(
                                                                                                                          "$",
                                                                                                                          ""
                                                                                                                        )} `
                                                                                                                    : "Price will be provided"
                                                                                                                }
                                                                                                                </span></b><b><span
                                                                                                                style="font-size:13.5pt;font-family:Roboto;color:rgb(38,38,38)"></span></b><span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)"><u></u><u></u></span>
                                                                                                    </p>
                                                                                                    <p align="center"
                                                                                                        style="margin:0cm;text-align:center">
                                                                                                        <b><span
                                                                                                                style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)">&#xFEFF;Or
                                                                                                                Best
                                                                                                                Offer</span></b><span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)"><u></u><u></u></span>
                                                                                                    </p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <p class="MsoNormal" align="center"
                                                                                    style="text-align:center">
                                                                                    <u></u>&nbsp;<u></u></p>
                                                                                <div align="center">
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="border-radius:0px;width:inherit;border-spacing:0px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="padding:7.5pt 15pt">
                                                                                                    <div align="center">
                                                                                                        <table
                                                                                                            border="0"
                                                                                                            cellspacing="0"
                                                                                                            cellpadding="0"
                                                                                                            style="background:rgb(255,194,45)">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="padding:0pt 30pt;">
                                                                                                                        <p class="MsoNormal"
                                                                                                                            align="center"
                                                                                                                            style="text-align:center">
                                                                                                                            <span
                                                                                                                                style="color:black"><a
                                                                                                                                    href="tel:800-478-5840"
                                                                                                                                    target="_blank"><b><span
                                                                                                                                            style="font-size:11.5pt;font-family:Roboto;color:rgb(38,38,38);text-decoration-line:none">(800)
                                                                                                                                            478-5840</span></b></a></span><u></u><u></u>
                                                                                                                        </p>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                         
                                                          <div align="center">
                                                            <table style="max-width: 620px; width: 100%; border-collapse: collapse;">
                                                            <tbody>
                                                                ${imagesHTML}
                                                              </tbody>
                                                            </table>
                                                          </div>

                                                            <p class="MsoNormal" align="center"
                                                                style="text-align:center"></p>
                                                            <div align="center" 
                                                            style="padding:0 0 11.25pt 0">
                                                                <table border="0" cellspacing="0" cellpadding="0"
                                                                    width="100%"
                                                                    style="width:620.775px;background:rgb(38,38,38)">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="100%" valign="top"
                                                                                style="width:620.775px;padding:0cm">
                                                                                    <div class="MsoNormal" align="center"
                                                                                        style="text-align:start;padding:7.5pt 7.5pt 7.5pt 15pt"><span
                                                                                        style="font-family:Arial,sans-serif;color:white;text-align:start">${
                                                                                          sendData.dec
                                                                                        }</span><u></u><u></u>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <p class="MsoNormal" align="center"
                                                                style="text-align:center"><u></u></p>
                                                            <div align="center">
                                                                <table border="0" cellspacing="0" cellpadding="0"
                                                                    width="100%"
                                                                    style="width:620.775px;background:rgb(38,38,38)">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="55%" valign="top"
                                                                                style="width:341.425px;padding:0cm">
                                                                                <div align="center">
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="width:341.425px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td valign="top"
                                                                                                    style="padding:7.5pt 7.5pt 7.5pt 15pt">
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <b><span
                                                                                                                style="font-family:Roboto;color:white">+1
                                                                                                                (800)
                                                                                                                478-5840</span></b><span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)"><u></u><u></u></span>
                                                                                                    </p>
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <b><span
                                                                                                                style="font-family:Roboto;color:white"><a
                                                                                                                    href="mailto:sales@calvingroupinc.com"
                                                                                                                    target="_blank">sales@calvingroupinc.com</a></span></b><span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)"><u></u><u></u></span>
                                                                                                    </p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                            <td width="45%" valign="top"
                                                                                style="width:279.35px;padding:0cm">
                                                                                <div align="center">
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="border-radius:0px;width:inherit;border-spacing:0px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="padding:7.5pt 15pt 7.5pt 7.5pt">
                                                                                                    <table border="0"
                                                                                                        cellspacing="0"
                                                                                                        cellpadding="0"
                                                                                                        style="background:rgb(255,194,45)">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    style="padding:0pt 30pt">
                                                                                                                    <p class="MsoNormal"
                                                                                                                        align="center"
                                                                                                                        style="text-align:center">
                                                                                                                        <span
                                                                                                                            style="color:black"><a
                                                                                                                                href="https://d6iaeoabb.cc.rs6.net/tn.jsp?f=001JgpRscSH2SKISdyT3T2XYC0V2HgREPT-IlHFXtIvKaS5zAPWQ3f0LPHPRVjMAjIrAzMFd1lVL52fdJFy6bavBzybysN3PW6-3OYCxVtsBgFXg0vL2bDl9bL8tnHproGqpCwzp6N4Pwdowub41fe1yyjyHxNJTnQN&amp;c=bApELd4KU9cQtbqoOIfZWaRI9Fevncz6ph_cs2Qn2V00pOhX-f8SQA==&amp;ch=oGMKMSfhCAbVxXbGaP38XMo-nqDtYZ2R26ue1YJmG8YrfGE7uD7Otg=="
                                                                                                                                target="_blank"
                                                                                                                                data-saferedirecturl="https://www.google.com/url?q=https://d6iaeoabb.cc.rs6.net/tn.jsp?f%3D001JgpRscSH2SKISdyT3T2XYC0V2HgREPT-IlHFXtIvKaS5zAPWQ3f0LPHPRVjMAjIrAzMFd1lVL52fdJFy6bavBzybysN3PW6-3OYCxVtsBgFXg0vL2bDl9bL8tnHproGqpCwzp6N4Pwdowub41fe1yyjyHxNJTnQN%26c%3DbApELd4KU9cQtbqoOIfZWaRI9Fevncz6ph_cs2Qn2V00pOhX-f8SQA%3D%3D%26ch%3DoGMKMSfhCAbVxXbGaP38XMo-nqDtYZ2R26ue1YJmG8YrfGE7uD7Otg%3D%3D&amp;source=gmail&amp;ust=1718252197294000&amp;usg=AOvVaw3xt5MP6Crm2lzDvWYdRYul"><b><span
                                                                                                                                        style="font-size:10.5pt;font-family:Roboto;color:rgb(38,38,38);text-decoration-line:none">calvingroupinc.com</span></b></a></span><u></u><u></u>
                                                                                                                    </p>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <p class="MsoNormal" align="center"
                                                                style="text-align:center"><u></u></p>
                                                            <div align="center">
                                                                <table border="0" cellspacing="0" cellpadding="0"
                                                                    width="100%"
                                                                    style="width:620.775px;background:rgb(38,38,38)">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="100%" valign="top"
                                                                                style="width:620.775px;padding:0cm">
                                                                                <div align="center">
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="width:620.775px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td valign="top"
                                                                                                    style="padding:7.5pt 15pt">
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <span
                                                                                                            style="font-size:9pt;font-family:Roboto;color:rgb(204,204,204)">We
                                                                                                            specialize
                                                                                                            in buying /
                                                                                                            selling
                                                                                                            asphalt
                                                                                                            paving
                                                                                                            equipment in
                                                                                                            the United
                                                                                                            States,
                                                                                                            Canada,
                                                                                                            Mexico as
                                                                                                            well as
                                                                                                            other
                                                                                                            international
                                                                                                            countries.
                                                                                                            If you are
                                                                                                            looking for
                                                                                                            or wanting
                                                                                                            to dispose
                                                                                                            of asphalt
                                                                                                            pavers, road
                                                                                                            wideners,
                                                                                                            material
                                                                                                            transfer
                                                                                                            vehicles,
                                                                                                            rollers or
                                                                                                            compactors,
                                                                                                            distributors,
                                                                                                            chip
                                                                                                            spreaders,
                                                                                                            please let
                                                                                                            us know.
                                                                                                            With over 30
                                                                                                            years of
                                                                                                            experience
                                                                                                            in the
                                                                                                            construction
                                                                                                            equipment
                                                                                                            sales
                                                                                                            business, we
                                                                                                            hope that we
                                                                                                            can be of
                                                                                                            assistance
                                                                                                            to you in
                                                                                                            your asphalt
                                                                                                            equipment
                                                                                                            needs.</span><span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)"><u></u><u></u></span>
                                                                                                    </p>
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)"><u></u>&nbsp;<u></u></span>
                                                                                                    </p>
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <span
                                                                                                            style="font-size:9pt;font-family:Roboto;color:rgb(204,204,204)">For
                                                                                                            more asphalt
                                                                                                            paving
                                                                                                            equipment,
                                                                                                            please visit
                                                                                                            us online
                                                                                                            at&nbsp;</span><span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)"><a
                                                                                                                href="https://d6iaeoabb.cc.rs6.net/tn.jsp?f=001JgpRscSH2SKISdyT3T2XYC0V2HgREPT-IlHFXtIvKaS5zAPWQ3f0LPHPRVjMAjIrAzMFd1lVL52fdJFy6bavBzybysN3PW6-3OYCxVtsBgFXg0vL2bDl9bL8tnHproGqpCwzp6N4Pwdowub41fe1yyjyHxNJTnQN&amp;c=bApELd4KU9cQtbqoOIfZWaRI9Fevncz6ph_cs2Qn2V00pOhX-f8SQA==&amp;ch=oGMKMSfhCAbVxXbGaP38XMo-nqDtYZ2R26ue1YJmG8YrfGE7uD7Otg=="
                                                                                                                target="_blank"
                                                                                                                data-saferedirecturl="https://www.google.com/url?q=https://d6iaeoabb.cc.rs6.net/tn.jsp?f%3D001JgpRscSH2SKISdyT3T2XYC0V2HgREPT-IlHFXtIvKaS5zAPWQ3f0LPHPRVjMAjIrAzMFd1lVL52fdJFy6bavBzybysN3PW6-3OYCxVtsBgFXg0vL2bDl9bL8tnHproGqpCwzp6N4Pwdowub41fe1yyjyHxNJTnQN%26c%3DbApELd4KU9cQtbqoOIfZWaRI9Fevncz6ph_cs2Qn2V00pOhX-f8SQA%3D%3D%26ch%3DoGMKMSfhCAbVxXbGaP38XMo-nqDtYZ2R26ue1YJmG8YrfGE7uD7Otg%3D%3D&amp;source=gmail&amp;ust=1718252197294000&amp;usg=AOvVaw3xt5MP6Crm2lzDvWYdRYul"><span
                                                                                                                    style="font-size:9pt;color:rgb(204,204,204)">www.calvingroupinc.com</span></a></span><span
                                                                                                            style="font-size:9pt;font-family:Roboto;color:rgb(204,204,204)">.</span><span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)"><u></u><u></u></span>
                                                                                                    </p>
                                                                                                    <p
                                                                                                        style="margin:0cm">
                                                                                                        <span
                                                                                                            style="font-size:9pt;font-family:Roboto;color:rgb(204,204,204)">If
                                                                                                            there is
                                                                                                            something
                                                                                                            specific you
                                                                                                            are looking
                                                                                                            for or
                                                                                                            wanting to
                                                                                                            sell, let us
                                                                                                            know!&#xFEFF;</span><span
                                                                                                            style="font-size:10.5pt;font-family:Roboto;color:rgb(64,63,66)"><u></u><u></u></span>
                                                                                                    </p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <p class="MsoNormal" align="center"
                                                                style="text-align:center"></p>
                                                            <div align="center">
                                                                <table border="0" cellspacing="0" cellpadding="0"
                                                                    width="100%"
                                                                    style="width:620.775px;background:rgb(38,38,38)">
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
            <tr>
                <td valign="top" style="background:white;padding:0cm">
                    <div align="center">
                        <table border="0" cellspacing="0" cellpadding="0" width="622" style="width:466.5pt">
                            <tbody>
                                <tr>
                                    <td valign="top" style="padding:0cm 7.5pt">
                                        <div align="center">
                                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                                style="width:602px">
                                                <tbody>
                                                    <tr>
                                                        <td valign="top"
                                                            style="border:1pt solid rgb(247,247,247);padding:0cm">
                                                            <div align="center">
                                                                <table border="0" cellspacing="0" cellpadding="0"
                                                                    width="100%" style="width:600.4px">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="100%" valign="top"
                                                                                style="width:600.4px;padding:0cm">
                                                                                <div align="center">
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="width:600.4px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="padding:7.5pt 15pt">
                                                                                                    <p align="center"
                                                                                                        style="margin:0cm;text-align:center">
                                                                                                        <span
                                                                                                            style="font-size:9pt;font-family:Verdana,sans-serif;color:rgb(89,89,89)">Calvin
                                                                                                            Group Inc |
                                                                                                            PO Box 100 |
                                                                                                            Grafton, OH
                                                                                                            44044
                                                                                                            US<u></u><u></u></span>
                                                                                                    </p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <div align="center">
                                                                                    <table border="0" cellspacing="0"
                                                                                        cellpadding="0" width="100%"
                                                                                        style="width:600.4px">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="padding:7.5pt 15pt">
                                                                                                    <p align="center"
                                                                                                        style="margin:0cm;text-align:center">
                                                                                                        <span
                                                                                                            style="font-size:9pt;font-family:Verdana,sans-serif;color:rgb(89,89,89)"><a
                                                                                                                href="https://visitor.constantcontact.com/do?p=un&amp;m=001cm0otovvshpPk7Lza9UK7A%3D&amp;ch=11d45310-a02f-11ea-babc-d4ae528ecd49&amp;ca=9e869777-b971-42cf-99cd-456a8de0c2cd"
                                                                                                                target="_blank"
                                                                                                                data-saferedirecturl="https://www.google.com/url?q=https://visitor.constantcontact.com/do?p%3Dun%26m%3D001cm0otovvshpPk7Lza9UK7A%253D%26ch%3D11d45310-a02f-11ea-babc-d4ae528ecd49%26ca%3D9e869777-b971-42cf-99cd-456a8de0c2cd&amp;source=gmail&amp;ust=1718252197294000&amp;usg=AOvVaw0Fd-sKWVXx42x5tA_AOCTl">Unsubscribe</a>&nbsp;|&nbsp;<a
                                                                                                                href="https://visitor.constantcontact.com/do?p=oo&amp;m=001cm0otovvshpPk7Lza9UK7A%3D&amp;ch=11d45310-a02f-11ea-babc-d4ae528ecd49&amp;ca=9e869777-b971-42cf-99cd-456a8de0c2cd"
                                                                                                                target="_blank"
                                                                                                                data-saferedirecturl="https://www.google.com/url?q=https://visitor.constantcontact.com/do?p%3Doo%26m%3D001cm0otovvshpPk7Lza9UK7A%253D%26ch%3D11d45310-a02f-11ea-babc-d4ae528ecd49%26ca%3D9e869777-b971-42cf-99cd-456a8de0c2cd&amp;source=gmail&amp;ust=1718252197294000&amp;usg=AOvVaw1IOWOXb_k5zR2gZLeqCdQw">Update
                                                                                                                Profile</a>&nbsp;|&nbsp;<a
                                                                                                                href="http://www.constantcontact.com/legal/about-constant-contact"
                                                                                                                target="_blank"
                                                                                                                data-saferedirecturl="https://www.google.com/url?q=http://www.constantcontact.com/legal/about-constant-contact&amp;source=gmail&amp;ust=1718252197294000&amp;usg=AOvVaw2q7zbeDt-6D5dUQUAlJ5Uu">Constant
                                                                                                                Contact
                                                                                                                Data
                                                                                                                Notice</a><u></u><u></u></span>
                                                                                                    </p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    `;

      setIsFormLoading(true);
      let payload = {
        Subject: `[Calvin Group] Equipment Info - ${data?.model?.manufacturer?.name} -${data?.year} - ${data?.model?.number}`,
        HtmlContent: htmlForm,
        ApplicationId: ApplicationId,
        ToEmail: sendData.email,
        EmailCopyToUser: true,
      };
      postContactUs(payload)
        .then((res) => {
          setIsFormLoading(false);
          toast.success("email send successfully");
          setSendData({
            emailCopy: false,
            email: "",
            dec: "",
            emailValidation: false,
          });
        })
        .catch((err) => {
          toast.error(err?.data);
          setIsFormLoading(false);
        });
    }
  };

  useEffect(() => {
    // if (data?.categorization?.category) {
    //     if (data?.categorization?.asset_type?.name) {
    //         handelSelectAssetTypeList({ AssetTypeId: data?.categorization?.asset_type?.id, AssetTypeName: data?.categorization?.asset_type?.name }, {
    //             Type: "AssetList",
    //         })
    //     }
    //     if (data?.categorization?.category?.name) {
    //         handelSelectAssetTypeList({ CategoryId: data?.categorization?.category?.id, CategoryName: data?.categorization?.category?.name }, {
    //             Type: "CategoryDetails",
    //         })
    //     }
    // }
  }, [data?.categorization]);

  useEffect(() => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = regex.test(sendData?.email);
    setSendData({ ...sendData, emailValidation: isValidEmail });
  }, [sendData?.email]);

  return (
    <>
      <Layout>
        <div className="container Detail" id="equip-detail">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "30px",
                height: "60vh",
                alignItems: "center",
              }}
            >
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
          ) : (
            <>
              {data?.categorization?.asset_type && (
                <div className="Equipment_bird_crump_details">
                  <span
                    className="Equipment_bird_crump_title_details"
                    onClick={() => {
                      navigate(`/equipment`);
                    }}
                  >
                    EQUIPMENT
                  </span>

                  {data?.categorization?.category?.name && (
                    <>
                      <span className="Equipment_bird_crump_title_Arrow_details">
                        {" > "}
                      </span>
                      <span
                        className="Equipment_bird_crump_title_details"
                        onClick={() => {
                          handelCategorizationBreadcrumb({
                            CategoryId: Number(
                              data?.categorization?.category?.id
                            ),
                            CategoryName: data?.categorization?.category?.name,
                            Tier1Details: [],
                          });
                        }}
                      >
                        {data?.categorization?.category?.name}
                      </span>
                    </>
                  )}

                  <>
                    <span className="Equipment_bird_crump_title_Arrow_details">
                      {" > "}
                    </span>
                    <span className="Equipment_bird_crump_title_Arrow_details">
                      {data?.year}&nbsp;
                      {data?.model?.manufacturer?.name}&nbsp;
                      {data?.model?.number}
                    </span>
                  </>
                </div>
              )}
              <div>
                <div>
                  <div className="Carousel-detail">
                    <Carousel
                      autoPlay={true}
                      infiniteLoop={true}
                      showThumbs={false}
                      centerMode={false}
                      showStatus={false}
                      interval={5000}
                    >
                      {images?.map((item, index) => {
                        return (
                          <div className="Carousel-img-detail">
                            <div
                              style={{
                                backgroundImage: `url("${item?.image}")`,
                                backgroundSize: "cover",
                              }}
                              className="carousel-img"
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.caption,
                                }}
                                className="w-full mb-10 text-white"
                              ></div>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>

                  <div className="Equipment_Grid_Detail_Page">
                    <div className="Equipment-details-box">
                      <div className="EquipmentCard_Data_box_Detail">
                        <div className="EquipmentCard_title">
                          <div className="EquipmentCard_Data_title_Detail">
                            {data?.year}&nbsp;
                            {data?.model?.manufacturer?.name}&nbsp;
                            {data?.model?.number}&nbsp;
                          </div>
                          <div className="EquipmentCard_sub_title_Detail">
                            {data?.meter_reading ? data?.meter_reading : "N/A"}{" "}
                            {data?.meter_reading_unit
                              ? data?.meter_reading_unit
                              : "Hours"}
                          </div>
                        </div>
                        <div className="EquipmentCard_DETAIL_Box">
                          <div className="EquipmentCard_DETAILS_Page_Title">
                            DETAILS:
                          </div>
                          <div className="EquipmentCard_DETAILS_Page">
                            {data?.notes || "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Detail_Page_Request_Box">
                      <div className="Detail_Page_Request_Title">
                        REQUEST A QUOTE
                      </div>
                      <div className="Detail_Page_Request_TextArea_Box">
                        <textarea
                          rows="15"
                          value={sendData.dec}
                          onChange={(e) =>
                            setSendData({ ...sendData, dec: e.target.value })
                          }
                          placeholder="Interested in this Unit?…&#10;Have Questions?…"
                          className="Detail_Page_Request_TextArea"
                        ></textarea>
                      </div>
                      <div
                        className="footer_subscribe_box"
                        style={{ marginTop: "10px" }}
                      >
                        <input
                          type="text"
                          value={sendData.email}
                          onChange={(e) =>
                            setSendData({ ...sendData, email: e.target.value })
                          }
                          placeholder="Type your email address..."
                          className="footer_input_box_detail"
                        />
                        <CustomButton
                          lable={"Send"}
                          CustomButtonClass={
                            sendData.emailValidation
                              ? "Email_Send_btn_TextArea_Yellow_Color"
                              : "CONTACT_btn_TextArea_Yellow_Color_disable Email_Send_btn_TextArea_Yellow_Color "
                          }
                          onClick={handleSubmitForm}
                        />
                      </div>
                      {/* 
                      <div
                        className="footer_subscribe_box"
                        style={{ marginTop: "10px" }}
                      >
                        <input
                          type="checkbox"
                          className="footer_subscribe_checkbox"
                          value={sendData.emailCopy}
                          checked={sendData.emailCopy}
                          onChange={(e) =>
                            setSendData({
                              ...sendData,
                              emailCopy: e.target.checked,
                            })
                          }
                        />
                        <div className="footer_subscribe_box_checkbox_text">
                          Email me a Copy
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};
export default Detail;
