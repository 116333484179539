const CustomButton =({lable,CustomButtonClass,onClick,type,disabled})=>{
    return(
        <>
            <button className={CustomButtonClass} onClick={onClick} type={type} disabled={disabled}>
                {lable}
            </button>
        </>
    )
}

export default CustomButton