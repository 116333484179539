import { ReCAPTCHA_sitekey, header } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import MenuIcon from "../../../assets/svg/menu.svg";
import LogoImg from "../../../assets/img/logo.png";
import SearchIcone from "../../../assets/img/search_Icone.png";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import EquipmentSearchModel from "../../header/Model/equipmentSearchModel";
import { useState } from "react";
import CommonModal from "../../common/CommonModal";
import { Formik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import CommonInput from "../../common/CommonInput";
import ReCAPTCHA from "react-google-recaptcha";
import { lead } from "../../../services/leadService";
import { toast } from "react-toastify";
import { ApplicationId } from "../../../api/url";

let subtitle;
const customStylesEquipmentSearch = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};

const afterOpenModal = () => {
  // references are now sync'd and can be accessed.
  // subtitle.style.color = "#f00";
};

const Header = ({
  handleCloseContactMenu,
  pageRefresh,
  openModal,
  tab,
  setPageRefresh,
}) => {
  const [open, setOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);


  const reCaptchaRef = useRef(null);

  const closeModal = () => {
    setIsOpen(false);
    handleCloseContactMenu();
  };

  const closeSearchModal = () => {
    setSearchModalIsOpen(false);
  };

  const handleSearchBtn = () => {
    if (searchText !== "") {
      localStorage.setItem("searchFilter", searchText);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (pageRefresh !== undefined) {
        setPageRefresh(!pageRefresh);
      }
      navigate("/equipment");
      closeSearchModal();
      // window.location.reload();
    }
  };

  const onClickButton = () => {
    setContactModalIsOpen(!contactModalIsOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const AccSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter Valid Email Address")
      .required("Please Enter E-mail Address"),
    firstName: Yup.string().required("Please Enter First Name"),
    lastName: Yup.string().required("Please Enter Last Name"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });

  const handleSubmitLead = (data, resetForm) => {
    setIsFormLoading(true);
    let payload = {
      FirstName: data?.firstName,
      LastName: data?.lastName,
      Email: data?.email,
      ReCaptchaResponse: reCaptchaRef?.current.getValue(),
      ApplicationId: ApplicationId
    };

    lead(payload)
      .then((res) => {
        setIsFormLoading(false);
        toast.success(res?.data?.message);
        resetForm();
        handleClose();
        reCaptchaRef?.current?.reset();
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setIsFormLoading(false);
      });
  }

  return (
    <>
      <div className="Header">
        <div className="container">
          <div className="navigation_link_header">
            <Link className="NavLink" to="/">
              <div className="navigation_header_logo_img">
                <img src={LogoImg} alt="logo" width={"100%"} />
              </div>
            </Link>
            <div className="navigation_link_header_part">
              <div className="navigation_header_box">
                {/* <div class="search-bar">
                                    <input
                                        type="text"
                                        class="textbox"
                                        placeholder='Search Equipment'
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                    <a class="search-btn" href="#">
                                        <img
                                            src={SearchIcone}
                                            alt=""
                                            onClick={() => handleSearchBtn()}
                                            width={"23px"}
                                        />
                                    </a>
                                </div> */}

                {tab === "home" && (
                  <input
                    onKeyDown={(event) =>
                      event.key === "Enter" ? handleSearchBtn() : null
                    }
                    onChange={(e) => setSearchText(e.target.value)}
                    type="search"
                    name="q"
                    class="search-text search-text-bg"
                    placeholder="Hit enter to search..."
                    autocomplete="off"
                  />
                )}
              </div>
              <Link className="NavLink" to="/equipment">
                <div className="navigation_header_link">
                  {header.Equipment}{" "}
                </div>
              </Link>
              <Link className="NavLink" to="/about">
                <div className="navigation_header_link">{header.About} </div>
              </Link>
              <Link className="NavLink" to="/contact">
                <div className="navigation_header_link">{header.Contact} </div>
              </Link>
              <div>
                <CustomButton
                  lable={header.Subscribe}
                  CustomButtonClass={"CONTACT_btn_Black_Color"}
                  onClick={handleOpen}
                />
              </div>
            </div>
          </div>

          <div className="navigation_link_header_mini_screen">
            <div className="mini_header">
              <Link className="NavLink" to="/">
                <div className="navigation_header_logo_img">
                  <img src={LogoImg} alt="logo" width={"100%"} />
                </div>
              </Link>
              <div className="dropdown">
                <button className="dropbtn" onClick={onClickButton}>
                  <img src={MenuIcon} alt="" className="dropbtn_icon" />
                </button>
              </div>
            </div>
            <div
              className={`dropdown-content ${contactModalIsOpen ? "open" : "close"
                }`}
            >
              <div className="navigation_header_box">
                {tab === "home" && (
                  <input
                    onKeyDown={(event) =>
                      event.key === "Enter" ? handleSearchBtn() : null
                    }
                    onChange={(e) => setSearchText(e.target.value)}
                    type="search"
                    name="q"
                    class="search-bar-container"
                    placeholder="Hit enter to search..."
                    autocomplete="off"
                  />
                )}
              </div>
              <Link
                className={"NavLink"}
                to="/equipment"
                onClick={() => searchModalIsOpen(false)}
              >
                <div className="navigation_header_link">{header.Equipment}</div>
              </Link>
              <Link
                className="NavLink"
                to="/about"
                onClick={() => searchModalIsOpen(false)}
              >
                <div
                  className="navigation_header_link"
                  onClick={() => searchModalIsOpen(false)}
                >
                  {header.About}
                </div>
              </Link>
              <Link
                className="NavLink"
                to="/contact"
                onClick={() => searchModalIsOpen(false)}
              >
                <div className="navigation_header_link">{header.Contact}</div>
              </Link>
              <div className="NavLink" style={{ marginTop: "16px" }}>
                <CustomButton
                  lable={header.Subscribe}
                  CustomButtonClass="CONTACT_btn"
                  onClick={handleOpen}
                />
              </div>
            </div>
          </div>
          {tab === "home" && (
            <input
              onKeyDown={(event) =>
                event.key === "Enter" ? handleSearchBtn() : null
              }
              onChange={(e) => setSearchText(e.target.value)}
              type="search"
              name="q"
              class="search-text search-text-sm"
              placeholder="Hit enter to search..."
              autocomplete="off"
            />
          )}
        </div>
      </div>

      <Modal
        isOpen={searchModalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeSearchModal}
        style={customStylesEquipmentSearch}
        contentLabel="Example Modal"
      >
        <div>
          <EquipmentSearchModel
            closeSearchModal={closeSearchModal}
            pageRefresh={pageRefresh}
            setPageRefresh={setPageRefresh}
          />
        </div>
      </Modal>

      <CommonModal isOpen={open} onClose={handleClose}>
        <Formik
          initialValues={{
            email: "",
            firstName: "",
            lastName: "",
            reCaptcha: "",
          }}
          validationSchema={AccSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmitLead(values, resetForm)
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="contact_grid_box_top contact_grid_box_top_name">
                <CommonInput
                  title="First Name"
                  values={values}
                  placeholder={"First name"}
                  errors={errors}
                  handleChange={handleChange}
                  touched={touched}
                  id="firstName"
                  name="firstName"
                  type="firstName"
                />


                <CommonInput
                  title="Last Name"
                  values={values}
                  placeholder={"Last name"}
                  errors={errors}
                  handleChange={handleChange}
                  touched={touched}
                  id="lastName"
                  name="lastName"
                  type="lastName"
                />
              </div>

              <div className="Contact_Page_field Contact_Page_Name_Field">
                <input
                  type="text"
                  placeholder={"Email"}
                  id="email"
                  name="email"
                  className="contact_page_input contact_input_fn"
                  value={values.email}
                  onChange={(e) => handleChange(e)}
                />

                {errors?.email && touched?.email && (
                  <div
                    style={{ fontSize: 14, textAlign: "left" }}
                    className="error_message"
                  >
                    {errors?.email}
                  </div>
                )}
              </div>



              <div className="ReCAPTCHA_Box">
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  size="normal"
                  sitekey={ReCAPTCHA_sitekey}
                  onChange={(captchaCode) => {
                    setFieldValue("reCaptcha", captchaCode);
                  }}
                />
                {errors?.reCaptcha && touched?.reCaptcha && (
                  <div
                    style={{ fontSize: 14, textAlign: "left", marginTop: 10 }}
                    className="error_message"
                  >
                    {errors?.reCaptcha}
                  </div>
                )}
              </div>

              <div className="buttonStyle">

                <div style={{ marginRight: "10px" }}>
                  {isFormLoading ?
                    <button className={`loader-button loading CONTACT_btn_Yellow_Color_loading`} disabled>
                      <div className="loading-spinner-sm"></div>
                    </button>
                    :
                    <CustomButton
                      lable={header.Submit}
                      CustomButtonClass={"CONTACT_btn_Yellow_Color"}
                    />
                  }
                </div>

                <div style={{ marginLeft: "10px" }}>
                  <CustomButton
                    lable={header.Cancel}
                    CustomButtonClass={"Cancel_btn_Yellow_Color"}
                    onClick={handleClose}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </CommonModal>
    </>
  );
};
export default Header;
