import api from '../api/api'
import postApi from '../api/postApi'
import * as url from '../api/url'

// get Machine Data
export const getAllMachine = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.MACHINE_LIST)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}

// Get Machine Details
export const getMachineDetail = id => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.MACHINE_DETAILS + id)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}

// Get Machine Details
export const getMachines = (page, limit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_MACHINES_LIST + `?pageNumber=${page}&pagesize=${limit}`)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}

// Post Machine List
export const postMachinesList = (payload) => {
  return new Promise(async (resolve, reject) => {
    return postApi
      .post(url.GET_MACHINES_LIST , payload)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}


// Get Machine Details By ID
export const getMachineDetailsByID = (ID) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_MACHINES_DETAILS + `&assetAdvertisementId=${ID}`)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}



// Get Machine Details By ID
export const postSaveAdvertiseVisitLogs = (payload) => {
  return new Promise(async (resolve, reject) => {
    return postApi
      .post(url.POST_SAVEADVERTISE,payload)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}


export const getRelatedPost = id => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_RELATED_POST + id)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}

export const getMachinesDetails = id => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_CATEGORY_LIST + id)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}


export const getAllMachinesList =()=>{
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_CATEGORY_LIST)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}