import { useEffect, useRef, useState } from "react";
import Footer from "./Footer/footer"
import Header from "./Header/header"
import Modal from "react-modal";
import CustomButton from "../custom_components/CustomButton";
import { ApplicationId } from "../../api/url";
import { postContactUs } from "../../services/contactUsService";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { ReCAPTCHA_sitekey } from "../../Utils/Constants";
import upArrow from "../../assets/svg/up-arrow.svg";

const afterOpenModal = () => {
};

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};


const Layout = ({ children, pageRefresh, setPageRefresh, handleInventoryContactMenu,tab }) => {
    const reCaptchaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showTopBtn, setShowTopBtn] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);

    const AccSchema = Yup.object().shape({
        firstname: Yup.string().required("Please Enter First Name"),
        lastname: Yup.string().required("Please Enter Last Name"),
        email: Yup.string()
            .email("Enter Valid Email Address")
            .required("Please Enter E-mail Address"),
        message: Yup.string().required("Please Enter message"),
        phone: Yup.string().required("Please Enter Phone Number"),
        reCaptcha: Yup.string().required("recaptcha is a required field"),
    });

    const handleSubmitForm = (value, resetForm) => {
        const htmlForm = `
      <div style="font-size:25px">
        <div>
          <table>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
            }</a></td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
            }</td>
            </tr>
          </table>
        <div>
      </div>
    `;
        const htmlFormWidthInventory = `
        <div style="font-size:25px">
          <div>
            <table>
              <tr style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
            }</a></td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 0px 0px;color:#000;">Inventory</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.inventory ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 40px;color:#000;">Message</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
            }</td>
              </tr>
            </table>
          <div>
        </div>
      `;
        setIsLoading(true);
        let payload = {
            HtmlContent: handleInventoryContactMenu
                ? htmlFormWidthInventory
                : htmlForm,
            ApplicationId: ApplicationId,
        };
        postContactUs(payload)
            .then((res) => {
                reCaptchaRef?.current?.reset();
                setIsLoading(false);
                toast.success("email send successfully");
                closeModal();
                resetForm();
            })
            .catch((err) => {
                toast.error(err?.data);
                setIsLoading(false);
            });
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    return (
        <>
            <Header pageRefresh={pageRefresh}
                setPageRefresh={setPageRefresh} openModal={openModal} tab={tab}/>
            {children}
            <Footer openModal={openModal} />

            {showTopBtn && (
                <button
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="scroll_to_top_btn"
                    id="btn-back-to-top"
                    onClick={goToTop}
                >
                    <img src={upArrow} alt="" />
                </button>
            )}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="Modal">
                    {isLoading && (
                        <div className="loader_Box">
                            <div className="loading-spinner"></div>
                        </div>
                    )}
                    <div>
                        <Formik
                            initialValues={{
                                firstname: "",
                                lastname: "",
                                email: "",
                                phone: "",
                                location: "",
                                message: "",
                                inventory: handleInventoryContactMenu,
                                reCaptcha: "",
                            }}
                            validationSchema={AccSchema}
                            onSubmit={(values, { resetForm }) => {
                                handleSubmitForm(values, resetForm);
                            }}
                        >
                            {({
                                errors,
                                touched,
                                values,
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="contact_grid_box_top">
                                        <div className="contact_field">
                                            <label className="contact-input-label">First Name</label>
                                            <input
                                                type="text"
                                                id="userfirstname"
                                                name="firstname"
                                                className="contact_input"
                                                value={values.firstname}
                                                onChange={(e) => handleChange(e)}
                                            />

                                            {errors.firstname && touched.firstname && (
                                                <div
                                                    style={{ fontSize: 14, textAlign: "left" }}
                                                    className="error_message"
                                                >
                                                    {errors.firstname}
                                                </div>
                                            )}
                                        </div>
                                        <div className="contact_field">
                                            <label className="contact-input-label">Last Name</label>
                                            <input
                                                type="text"
                                                id="userfirstname"
                                                name="lastname"
                                                className="contact_input"
                                                value={values.lastname}
                                                onChange={(e) => handleChange(e)}
                                            />

                                            {errors.lastname && touched.lastname && (
                                                <div
                                                    style={{ fontSize: 14, textAlign: "left" }}
                                                    className="error_message"
                                                >
                                                    {errors.lastname}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="contact_grid_box_top">
                                        <div className="contact_field">
                                            <label className="contact-input-label">Email</label>
                                            <input
                                                type="email"
                                                id="useremail"
                                                name="email"
                                                className="contact_input"
                                                value={values.email}
                                                onChange={(e) => handleChange(e)}
                                            />

                                            {errors.email && touched.email && (
                                                <div
                                                    style={{ fontSize: 14, textAlign: "left" }}
                                                    className="error_message"
                                                >
                                                    {errors.email}
                                                </div>
                                            )}
                                        </div>
                                        <div className="contact_field">
                                            <label className="contact-input-label">
                                                Phone Number
                                            </label>
                                            <input
                                                type="number"
                                                id="userfirstname"
                                                name="phone"
                                                className="contact_input"
                                                value={values.phone}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            {errors.phone && touched.phone && (
                                                <div
                                                    style={{ fontSize: 14, textAlign: "left" }}
                                                    className="error_message"
                                                >
                                                    {errors.phone}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {handleInventoryContactMenu && (
                                        <div className="contact_grid_box_top contact_field">
                                            <label className=" contact-input-label">Inventory</label>
                                            <input
                                                type="text"
                                                id="inventory"
                                                name="inventory"
                                                className="contact_input"
                                                value={values?.inventory}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    )}
                                    <div className="contact_grid_box_top contact_field">
                                        <label className=" contact-input-label">Location</label>
                                        <input
                                            type="text"
                                            id="location"
                                            name="location"
                                            className="contact_input"
                                            value={values.location}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                    <div className="contact_grid_box_top contact_field">
                                        <label className=" contact-input-label">Message</label>
                                        <textarea
                                            rows="5"
                                            type="textarea"
                                            id="message"
                                            name="message"
                                            className="contact_input_textarea"
                                            value={values.message}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        {errors.message && touched.message && (
                                            <div
                                                style={{ fontSize: 14, textAlign: "left" }}
                                                className="error_message"
                                            >
                                                {errors.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="contact_grid_box_top contact_field google_recaptch_box">
                                        <ReCAPTCHA
                                            ref={reCaptchaRef}
                                            size="normal"
                                            sitekey={ReCAPTCHA_sitekey}
                                            onChange={(captchaCode) => {
                                                setFieldValue("reCaptcha", captchaCode);
                                                // onReCAPTCHAChange
                                            }}
                                        />
                                        {errors.reCaptcha && touched.reCaptcha && (
                                            <div
                                                style={{ fontSize: 14, textAlign: "left" }}
                                                className="error_message"
                                            >
                                                {errors.reCaptcha}
                                            </div>
                                        )}
                                    </div>
                                    <div className="button_grid">
                                        <CustomButton
                                            type="button"
                                            lable={"Cancel"}
                                            CustomButtonClass="cancel_btn"
                                            onClick={closeModal}
                                        />
                                        <CustomButton
                                            type="submit"
                                            disabled={isLoading}
                                            lable={"Submit"}
                                            CustomButtonClass={
                                                isLoading ? "Submit_loading_btn" : "Submit_btn"
                                            }
                                        />
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Layout