import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from './Page/Dashboard/dashboard';
import Equipment from './Page/Equipment/equipment';
import Detail from './Page/Detail/detail';
import About from './Page/About/about';
import Contact from './Page/Contact/contact';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path={"/"} element={<Dashboard />}></Route>
        <Route path={"/equipment"} element={<Equipment />}></Route>
        <Route path={"/detail/:category/:id"} element={<Detail />}></Route>
        <Route path={"/about"} element={<About />}></Route>
        <Route path={"/contact"} element={<Contact />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
