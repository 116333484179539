import { AboutString } from "../../Utils/Constants"
import Footer from "../../components/layout/Footer/footer"
import Header from "../../components/layout/Header/header"
import AboutImg from "../../assets/img/about_img.png"
import logo1 from "../../assets/img/logo1.png"
import logo2 from "../../assets/img/logo2.png"
import logo3 from "../../assets/img/logo3.png"
import DownArrow from "../../assets/svg/down-arrow.svg"
import "./about.css"
import CustomButton from "../../components/custom_components/CustomButton"
import Layout from "../../components/layout/layout"
import { useNavigate } from "react-router-dom"

const About = () => {
    const navigate = useNavigate();
    return (
        <>
            <Layout>
                <div className="container">
                    <div className='Equipment_bird_crump'>
                        <div className='Equipment_bird_crump_title' onClick={() => navigate(`/`)}>HOME</div>
                        <div className='Equipment_bird_crump_title_Arrow'>{" > "} </div>
                        <div className='Equipment_bird_crump_title'>About Us</div>
                    </div>

                    <div className="About_title">
                        <p>WITH OVER <b>30 YEARS OF EXPERIENCE</b>, </p>
                        <p>WE <b>SPECIALIZE</b> IN BUYING AND SELLING </p>
                        <p><b>USED ASPHALT EQUIPMENT</b>.</p>

                        <div className="DownArrow-box">
                            <img src={DownArrow} className="DownArrow-Img" />
                        </div>
                    </div>


                    <div className="About_Img"><img src={AboutImg} width={'100%'} />
                        <div className="About_Img_text">
                            We specialize in buying and selling asphalt paving equipment in the United States, Canada, Mexico as well as other international countries. If you are looking for or wanting to dispose of asphalt pavers, road wideners, material transfer vehicles, rollers or compactors, distributors, chip spreaders… please <span className="About_Img_text_Contact"
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                    })
                                    navigate(`/contact`)
                                }}
                            >contact us</span>
                        </div>
                        <div className="About_Logo_Img">
                            <img src={logo1} alt="" width={'100%'} />
                            <img src={logo2} alt="" width={'100%'} />
                            <img src={logo3} alt="" width={'100%'} />
                        </div>
                    </div>
                </div>
                <div className="about_C_U">
                    <div className="container">
                        <div className="About_LOOKING">LOOKING FOR SOMETHING SPECIFIC?</div>
                        <div className="About_Text">Are you looking for a specific machine but you don't see it in our <span className="Contact_Text" onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            });
                            navigate(
                                `/equipment`,
                            );
                        }
                        }>online inventory</span>? Not all of our machines are listed online. Let us know what machine you're looking for and we'll do the work to find you the best machine at the best price.</div>

                        <div className="About_Text_btn_box">
                            <CustomButton
                                lable={AboutString.Contact_Us}
                                CustomButtonClass={"About_Text_btn"
                                }
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                    });
                                    navigate(`/contact`)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default About