import "./contact.css"
import Layout from '../../components/layout/layout'
import EmailIcone from "../../assets/img/email-icone.png"
import PhoneIcone from "../../assets/img/phone-icone.png"
import ContactUsArrowIcone from "../../assets/img/Contact_us_arrow.png"
import { FooterString, ReCAPTCHA_sitekey } from "../../Utils/Constants"
import CustomButton from "../../components/custom_components/CustomButton"
import { useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom"
import { Formik } from "formik"
import { toast } from "react-toastify";
import { ApplicationId } from "../../api/url"
import { postContactUs } from "../../services/contactUsService"

const Contact = () => {
    const { state } = useLocation()
    const navigate = useNavigate();
    const reCaptchaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const handleInventoryContactMenu = state?.handleInventoryContactMenu
    const AccSchema = Yup.object().shape({
        firstname: Yup.string().required("Please Enter First Name"),
        lastname: Yup.string().required("Please Enter Last Name"),
        email: Yup.string()
            .email("Enter Valid Email Address")
            .required("Please Enter E-mail Address"),
        message: Yup.string().required("Please Enter message"),
        phone: Yup.string().required("Please Enter Phone Number"),
        reCaptcha: Yup.string().required("recaptcha is a required field"),
    });

    const handleSubmitForm = (value, resetForm) => {
        const htmlForm = `
      <div style="font-size:25px">
        <div>
          <table>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
            }</a></td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
            }</td>
            </tr>
          </table>
        <div>
      </div>
    `;
        const htmlFormWidthInventory = `
        <div style="font-size:25px">
          <div>
            <table>
              <tr style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
            }</a></td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 0px 0px;color:#000;">Inventory</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.inventory ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 40px;color:#000;">Message</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
            }</td>
              </tr>
            </table>
          <div>
        </div>
      `;
        setIsLoading(true);
        let payload = {
            HtmlContent: handleInventoryContactMenu
                ? htmlFormWidthInventory
                : htmlForm,
            ApplicationId: ApplicationId,
        };
        postContactUs(payload)
            .then((res) => {
                reCaptchaRef?.current?.reset();
                setIsLoading(false);
                toast.success("email send successfully");
                resetForm();
            })
            .catch((err) => {
                toast.error(err?.data);
                setIsLoading(false);
            });
    };
    return (
        <>
            <Layout>
                <div className="container">
                    <div className='EquipmentDataList'>
                        <div className='Equipment_bird_crump'>
                            {
                                <div>
                                    <span className='Equipment_bird_crump_title' onClick={() => navigate(`/`)}>HOME</span>
                                    <span className='Equipment_bird_crump_title_Arrow'>{" > "}</span>
                                    <span className='Equipment_bird_crump_title'>Contact</span>
                                </div>
                            }
                        </div>
                        <div className="Contact_Page_Grid">
                            <div className="Contact_Page_Left_Box">
                                <div className="Contact_Page_Grid_Title">
                                    You want equipment. <br/>We want to help you find it.
                                </div>

                                <div className="Contact_Page_Grid_Sub_Title">
                                    Let's make this happen together.
                                </div>
                                <div>
                                    <div>
                                        <a href={`mailto:${FooterString.EMAIL}`} className="Contact_Page_Email_Box">
                                            <div className="Contact_Page_Email__Img_Box">
                                                <img src={EmailIcone} alt="" width={"100%"} />
                                            </div>
                                            <div>
                                                <div className="Contact_Page_Email_Text">Email us at</div>
                                                <div className="Contact_Page_sub_Email_Text">{FooterString.EMAIL}</div>
                                            </div>
                                        </a>
                                    </div>
                                    <div>
                                        <a href={`tel:${FooterString.NUMBER}`} className="Contact_Page_Email_Box">
                                            <div className="Contact_Page_Email__Img_Box">
                                                <img src={PhoneIcone} alt="" width={"100%"} />
                                            </div>
                                            <div>
                                                <div className="Contact_Page_Email_Text">Call us at</div>
                                                <div className="Contact_Page_sub_Email_Text">{FooterString.NUMBER}</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="Contact_Page_Right_Box">
                                <div className="Contact_Page_Right_div">
                                    {isLoading && (
                                        <div className="loader_Box">
                                            <div className="loading-spinner"></div>
                                        </div>
                                    )}
                                    <div>
                                        <div className="contact_form_title">
                                            Send us a message
                                            <img src={ContactUsArrowIcone} alt="" width={"40px"} />
                                        </div>

                                        <div className="contact_form_sub_title">
                                            Click on each box below to enter your information
                                        </div>

                                        <Formik
                                            initialValues={{
                                                firstname: "",
                                                lastname: "",
                                                email: "",
                                                phone: "",
                                                location: "",
                                                message: "",
                                                inventory: handleInventoryContactMenu,
                                                reCaptcha: "",
                                            }}
                                            validationSchema={AccSchema}
                                            onSubmit={(values, { resetForm }) => {
                                                handleSubmitForm(values, resetForm);
                                            }}
                                        >
                                            {({
                                                errors,
                                                touched,
                                                values,
                                                handleSubmit,
                                                handleChange,
                                                setFieldValue,
                                            }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className="grid_box contact_grid_box_top_name">
                                                        <div className="Contact_Page_field Contact_Page_Name_Field">
                                                            <input
                                                                type="text"
                                                                id="userfirstname"
                                                                name="firstname"
                                                                className="contact_page_input contact_input_fn rs_contact_input_fn"
                                                                placeholder="First Name*"
                                                                value={values.firstname}
                                                                onChange={(e) => handleChange(e)}
                                                            />

                                                            {errors.firstname && touched.firstname && (
                                                                <div
                                                                    style={{ fontSize: 14, textAlign: "left" }}
                                                                    className="error_message"
                                                                >
                                                                    {errors.firstname}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="Contact_Page_field">
                                                            <input
                                                                type="text"
                                                                id="userfirstname"
                                                                placeholder="Last Name*"
                                                                name="lastname"
                                                                className="contact_page_input rs_contact_input_fn"
                                                                value={values.lastname}
                                                                onChange={(e) => handleChange(e)}
                                                            />

                                                            {errors.lastname && touched.lastname && (
                                                                <div
                                                                    style={{ fontSize: 14, textAlign: "left" }}
                                                                    className="error_message"
                                                                >
                                                                    {errors.lastname}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="Contact_Page_field">
                                                        <input
                                                            type="email"
                                                            id="useremail"
                                                            placeholder="Email Address*"
                                                            name="email"
                                                            className="contact_page_input"
                                                            value={values.email}
                                                            onChange={(e) => handleChange(e)}
                                                        />

                                                        {errors.email && touched.email && (
                                                            <div
                                                                style={{ fontSize: 14, textAlign: "left" }}
                                                                className="error_message"
                                                            >
                                                                {errors.email}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="Contact_Page_field">
                                                        <input
                                                            type="number"
                                                            id="userfirstname"
                                                            placeholder=" Phone Number*"
                                                            name="phone"
                                                            className="contact_page_input"
                                                            value={values.phone}
                                                            onChange={(e) => handleChange(e)}
                                                        />
                                                        {errors.phone && touched.phone && (
                                                            <div
                                                                style={{ fontSize: 14, textAlign: "left" }}
                                                                className="error_message"
                                                            >
                                                                {errors.phone}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {handleInventoryContactMenu && (
                                                        <div className="contact_grid_box_top Contact_Page_field">
                                                            <input
                                                                type="text"
                                                                id="inventory"
                                                                name="inventory"
                                                                placeholder="Inventory"
                                                                className="contact_page_input"
                                                                value={values?.inventory}
                                                                onChange={(e) => handleChange(e)}
                                                            />
                                                        </div>
                                                    )}
                                                    {/* <div className="contact_grid_box_top Contact_Page_field">
                                                    <label className=" contact-input-label">Location</label>
                                                    <input
                                                        type="text"
                                                        id="location"
                                                        name="location"
                                                        className="contact_page_input"
                                                        value={values.location}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div> */}
                                                    <div className="contact_grid_box_top Contact_Page_field">
                                                        <textarea
                                                            rows="5"
                                                            type="textarea"
                                                            id="message"
                                                            name="message"
                                                            className="contact_page_input_textarea"
                                                            value={values.message}
                                                            placeholder="Tell us what you're looking for and how we can help"
                                                            onChange={(e) => handleChange(e)}
                                                        />
                                                        {errors.message && touched.message && (
                                                            <div
                                                                style={{ fontSize: 14, textAlign: "left" }}
                                                                className="error_message"
                                                            >
                                                                {errors.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="contact_grid_box_top Contact_Page_field google_recaptch_box" style={{ alignItems: "flex-start" }}>
                                                        <ReCAPTCHA
                                                            ref={reCaptchaRef}
                                                            size="normal"
                                                            sitekey={ReCAPTCHA_sitekey}
                                                            onChange={(captchaCode) => {
                                                                setFieldValue("reCaptcha", captchaCode);
                                                                // onReCAPTCHAChange
                                                            }}
                                                        />
                                                        {errors.reCaptcha && touched.reCaptcha && (
                                                            <div
                                                                style={{ fontSize: 14, textAlign: "left" }}
                                                                className="error_message"
                                                            >
                                                                {errors.reCaptcha}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="button_grid" style={{ justifyContent: "flex-start" }}>
                                                        <CustomButton
                                                            type="submit"
                                                            disabled={isLoading}
                                                            lable={"Send message"}
                                                            CustomButtonClass={
                                                                isLoading ? "Submit_loading_btn Send_message_btn" : "Submit_btn Send_message_btn"
                                                            }
                                                        />
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Contact