import { useNavigate } from "react-router-dom";
import "./machinesCard.css";
const MachinesCard = ({ item, title, img }) => {
  const navigate = useNavigate();
  const imgLogo = [14, 16, 18, 17, 19, 23, 24, 27, 28, 30, 31, 21, 20, 15];
  return (
    <>
      <div style={{ background: "#fff" }}>
        <div
          className="MachinesCard_Box"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            const encodeURICom = encodeURIComponent(
              `${
                item.assetAdvertisementId
              }-${item?.primary_image?.name.replaceAll(" ", "-")}`
            );
            navigate(`/equipment`, { state: item });
            window.location.reload();
          }}
          style={{
            backgroundImage: `url("/machinesImg/${
              imgLogo.filter((logo) => logo === item.CategoryId)?.length > 0
                ? `${item.CategoryId}.svg`
                : "engine-gears-icon.svg"
            }")`,
            backgroundSize:
              imgLogo.filter((logo) => logo === item.CategoryId)?.length > 0
                ? "87%"
                : "45%",
          }}
        >
          <div className="MachinesCard_title">{title}</div>
        </div>
      </div>
    </>
  );
};
export default MachinesCard;
